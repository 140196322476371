import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FeatureDetail,
  ImpactAreaRisk,
  ImpactAreaRiskDetails,
  Infrastructure,
  NameState,
  NameType,
  RiskRegisterDetails,
  WizardFeature,
} from '../data-access/models/hazard-types.model';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RiskWizard } from '../data-access/models/risk-wizard';
import { MapService } from '../../../data-access/services/map.service';
import { ComponentFactory } from '../../../../../shared/services/ComponentFactory/component-factory.service';
import { SidebarService } from '../../../ui/sidebar/data-access/sidebar.service';
import { CreateBoundaryComponent } from './create-boundary/create-boundary.component';
import { switchMap, tap, map } from 'rxjs';
import { CreateEvent } from './create-event';
import { ModalService } from '../../../../../shared/services/modal/modal.service';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Draw } from 'ol/interaction';
import { MultiPolygon, Polygon } from 'ol/geom';
import { RiskWizardService } from '../data-access/services/risk-wizard.service';
import { Feature, Map as olMap } from 'ol';
import { RegisterRiskComponent } from './register-risk/register-risk.component';
import BaseLayer from 'ol/layer/Base';
import { Layer } from 'ol/layer';
import { Stroke, Style } from 'ol/style';
import { LayersService } from '../../layers-page/data-access/services/layers.service';
import {
  CategoryInfo,
  LayerInfo,
} from '../../catalogue-page/data-access/catalogue-config.model';
import { FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { BoundaryData } from '../data-access/models/boundary-data.model';
import {
  BuildingProperties,
  DataFromGeoServer,
} from '../../../data-access/models/bridge-data.model';
import {
  InfrastructureData,
  RiskRegistryInputs,
} from '../data-access/models/risk-registry-inputs.model';
import {
  ImpactAreaRisDetails,
  InfrastructureDetails,
  RiskDetail,
} from '../data-access/models/risk-detail.model';
import {
  convertToTitleCase,
  defaultHazardStyle,
} from '../../../../../shared/utils/utils';

@Component({
  selector: 'app-risk-wizard',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FloatLabelModule,
    DropdownModule,
    RadioButtonModule,
    FileUploadModule,
    TableModule,
    MultiSelectModule,
  ],
  templateUrl: './risk-wizard.component.html',
  styleUrl: './risk-wizard.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskWizardComponent implements OnInit, OnDestroy {
  readonly mapService: MapService = inject(MapService);
  readonly layerService: LayersService = inject(LayersService);
  readonly riskWizardService: RiskWizardService = inject(RiskWizardService);
  readonly componentFactoryService: ComponentFactory = inject(ComponentFactory);
  readonly sidebarService: SidebarService = inject(SidebarService);
  readonly modalService: ModalService = inject(ModalService);

  readonly hazards: NameState[] = RiskWizard.ALL;
  readonly analysisZones: NameType[] = RiskWizard.ANALYSIS_ZONES;
  readonly events: string[] = RiskWizard.EVENT;
  readonly infrastructures: string[] = RiskWizard.INFRASTRUCTURE;
  features: FeatureDetail[] = [];
  readonly selectionTypes: NameType[] = RiskWizard.SELECTION_TYPE;
  readonly ADMIN_KEY: string = 'Administrative Boundaries';

  public form: FormGroup;
  public isUserDefined: boolean = false;

  private _map: olMap = this.mapService.getMap();
  private _drawnFeatures: Feature<any>;
  private _admin_Group: CategoryInfo = this.layerService.catalogue.find(
    (c) => c.name === this.ADMIN_KEY
  );
  private _admin_GroupI: number = this.layerService.catalogue.indexOf(
    this._admin_Group
  );
  private _selection: string = '';

  constructor() {
    this.mapService.analysisZoneLayer = undefined;
    this.form = new FormGroup({
      hazardType: new FormControl<NameState | null>(null),
      event: new FormControl(''),
      analysisZone: new FormControl(''),
      infrastructure: new FormControl<string[] | null>(null),
      feature: new FormControl<WizardFeature[] | null>(null),
      selectionType: new FormControl({} as NameType),
    });

    this.form.get('analysisZone').valueChanges.subscribe((value: NameType) => {
      if (value.name === 'User Defined') {
        this.isUserDefined = true;
      } else {
        this.isUserDefined = true;
        const layer: LayerInfo = this._admin_Group.layers.find(
          (l) => l.name === value.name
        );
        const lI: number = this._admin_Group.layers.indexOf(layer);
        if (!this.layerService.persistedLayers[this.ADMIN_KEY]) {
          this.layerService.addLayerToMap(
            this._admin_Group,
            this._admin_GroupI,
            layer,
            lI
          );
        } else if (
          !this.layerService.persistedLayers[this.ADMIN_KEY].layers[value.name]
        ) {
          this.layerService.addLayerToMap(
            this._admin_Group,
            this._admin_GroupI,
            layer,
            lI
          );
        } else {
          this._map.getLayers().forEach((subLayers: BaseLayer) => {
            if (subLayers.getProperties()['title'] != undefined) {
              subLayers.getLayersArray().forEach((layer: Layer<any>) => {
                /*if (
                  this.mapService.analysisZoneLayer &&
                  layer.get('title') ===
                    this.mapService.analysisZoneLayer.get('title')
                ) {
                  layer.setVisible(false);
                } else*/
                if (layer.get('title') === value.type) {
                  layer.setVisible(true);
                  this.mapService.analysisZoneLayer = layer;
                  return;
                }
              });
            }
          });
        }
      }
    });

    this.form.get('infrastructure').valueChanges.subscribe((inf: string[]) => {
      this.features = RiskWizard.FEATURE.filter((i) =>
        inf.includes(i.infrastructure)
      );
    });
  }

  ngOnInit() {
    console.log('Risk Wizard OnInit Called');
  }

  ngOnDestroy() {
    console.log('Risk Wizard OnDestroy Called');
  }

  get isFileUpload(): boolean {
    return (
      (this.form.get('selectionType').value as NameType)?.type === 'Upload'
    );
  }

  drawSelection() {
    let component = this.componentFactoryService.appendComponentToBody(
      CreateBoundaryComponent
    );
    this.sidebarService.close();
    this._handleCreateEvent(component, component.instance.createEvent);
    this.riskWizardService.hazardSource = new VectorSource();
    this.riskWizardService.hazardLayer = new VectorLayer({
      source: this.riskWizardService.hazardSource,
    });

    this.mapService.interactionType.set('Draw');

    this._map.addLayer(this.riskWizardService.hazardLayer);

    this.riskWizardService.interaction = new Draw({
      type: 'Polygon',
      source: this.riskWizardService.hazardSource,
    });

    this._map.addInteraction(this.riskWizardService.interaction);

    this.riskWizardService.interaction.on('drawstart', () => {
      this.riskWizardService.completePolygon = false;
    });

    this.riskWizardService.interaction.on('drawend', (event) => {
      this.riskWizardService.completePolygon = true;
      let eventGeom: Polygon = event.feature.getGeometry() as Polygon;
      let newCoordinates = eventGeom.getCoordinates() as any;

      this.riskWizardService.coordinates.push(newCoordinates);

      if (!this.riskWizardService.hazardBoundary) {
        this.riskWizardService.hazardBoundary = new Feature(
          new MultiPolygon(this.riskWizardService.coordinates)
        );

        this.riskWizardService.hazardSource.addFeature(
          this.riskWizardService.hazardBoundary
        );
      } else {
        this.riskWizardService.hazardBoundary
          .getGeometry()
          .setCoordinates(this.riskWizardService.coordinates);
      }

      this.riskWizardService.hazardSource.removeFeature(event.feature);
    });
  }

  protected readonly Object = Object;

  boundarySelection() {
    this.mapService.interactionType.set('Select');
    this.mapService.isPointSelect = false;
    let component = this.componentFactoryService.appendComponentToBody(
      CreateBoundaryComponent
    );
    this.sidebarService.close();
    this._handleCreateEvent(component, component.instance.createEvent);
    this.riskWizardService.hazardSource = new VectorSource();
    this.riskWizardService.hazardLayer = new VectorLayer({
      source: this.riskWizardService.hazardSource,
      style: defaultHazardStyle(),
    });

    this._map.addLayer(this.riskWizardService.hazardLayer);
  }

  pointSelection() {
    this.mapService.interactionType.set('Select');
    this.mapService.isPointSelect = true;
    let component = this.componentFactoryService.appendComponentToBody(
      CreateBoundaryComponent
    );
    this.sidebarService.close();
    this._handleCreateEvent(component, component.instance.createEvent);
    this.riskWizardService.hazardSource = new VectorSource();

    this.riskWizardService.hazardLayer = new VectorLayer({
      source: this.riskWizardService.hazardSource,
      style: new Style({
        stroke: new Stroke({
          color: 'rgb(255,16,16)',
          width: 2,
        }),
      }),
    });

    this._map.addLayer(this.riskWizardService.hazardLayer);
  }

  _handleCreateEvent(
    component: ComponentRef<CreateBoundaryComponent>,
    event: EventEmitter<CreateEvent<BoundaryData>>
  ) {
    event
      .pipe(
        switchMap((event) =>
          this.modalService
            .showConfirmation(
              event.create
                ? 'Are you sure you want to create this boundary?'
                : 'Are you sure you want to cancel?'
            )
            .pipe(map((confirmed) => ({ confirmed, event })))
        ),
        tap(({ confirmed, event }) => {
          // if no and its not create
          if (!confirmed && !event.create) {
            return;
          } else if (confirmed && !event.create) {
            this._removeInteractionAndClear(component);
            this.sidebarService.open();
            return;
          } else if (confirmed && event.create) {
            this._removeInteractionAndClear(component);
            this._processBoundaryDraw(event.model);
          }
        })
      )
      .subscribe();
  }

  _removeInteractionAndClear(component: ComponentRef<CreateBoundaryComponent>) {
    this._selection = this.mapService.interactionType();
    this.mapService.interactionType.set('Info');
    component.destroy();
    this._map.removeInteraction(this.riskWizardService.interaction);
    this._map.removeLayer(this.riskWizardService.hazardLayer);
    this.riskWizardService.hazardSource.clear();
  }

  async _processBoundaryDraw(data: BoundaryData) {
    let hazardType: string = this.form.get('hazardType').value?.name;
    let infrastructure: string[] = this.form.get('infrastructure').value;
    let analysisZone: string = this.form.get('analysisZone').value?.name;
    let featureType: string[] = this.form
      .get('feature')
      .value.map((a) => a.name);

    let propName: string = '';
    switch (analysisZone) {
      case 'Local Government Areas':
        propName = 'name';
        break;
      case 'Sub-Catchments':
        propName = 'SC_NAME';
        break;
      case 'Human Settlement Areas':
        propName = 'sub_name';
        break;
      default:
        propName = '';
        break;
    }

    const locations: string[] =
      this._selection === 'Draw'
        ? ['User Defined Area ']
        : data.vectorSourceFeatures.map(
            (f) => `${f.getProperties()[propName]} `
          );

    this._drawnFeatures = data.feature;
    const coordinates = data.feature.getGeometry().getCoordinates();
    const coordinatesStr = coordinates[0][0]
      .flat()
      .toString()
      .replaceAll(',', ' ');

    let inputData: RiskRegistryInputs = {
      type: hazardType,
      analysisZone: analysisZone,
      consequenceStatement: '',
      hazardStatement: '',
      infrastructure: {} as InfrastructureData,
      event: this.form.get('event').value,
      locations: locations.join(', '),
      selectedFeature: this._drawnFeatures,
      selectedCoordinates: coordinates,
    };

    try {
      for (const item of infrastructure) {
        let dataFromProxy: DataFromGeoServer;
        if (item === 'Transport sector' && featureType.includes('Bridges')) {
          dataFromProxy = await this.mapService.getBridgeData(coordinatesStr);
          inputData.infrastructure[item] = {
            data: dataFromProxy,
            count: dataFromProxy.numberReturned,
            feature: 'Bridges',
            impactedCount: dataFromProxy.numberReturned,
            hh: '',
          };
        } else if (
          item === 'Built environment' &&
          featureType.includes('Buildings')
        ) {
          dataFromProxy = await this.mapService.getBuildingData(coordinatesStr);
          const hhTxt: string[] = [];
          const hh = dataFromProxy.features.reduce((uniq, curr) => {
            const hazardVal = (curr.properties as BuildingProperties).hh_1pc;
            if (hazardVal.toString() !== '0') {
              uniq[hazardVal] = (uniq[hazardVal] || 0) + 1;
            }
            return uniq;
          }, {} as Record<string, number>);
          let impactedCount = 0;
          for (const key in hh) {
            hhTxt.push(`HH${key}: ${hh[key]}`);
            impactedCount += hh[key];
          }
          inputData.infrastructure[item] = {
            data: dataFromProxy,
            count: dataFromProxy.numberReturned,
            feature: 'Buildings',
            impactedCount: impactedCount,
            hh: `\nNumber of houses by Hydraulic Hazard: ${hhTxt.join(', ')}.`,
          };
        } else {
          inputData.infrastructure[item] = {
            data: null,
            count: 0,
            feature: RiskWizard.FEATURE.find((f) => f.infrastructure === item)
              .name,
            impactedCount: 0,
            hh: '',
          };
        }
      }

      inputData.hazardStatement = `${
        inputData.event
      } ${hazardType} occur in ${locations.join(', ')}${analysisZone}`;
      const stmt: string[] = [];
      for (const key in inputData.infrastructure) {
        stmt.push(
          `${key}: ${inputData.infrastructure[key].impactedCount} of ${
            inputData.infrastructure[key].count
          } in ${locations.join(', ')}are impacted.${
            inputData.infrastructure[key].hh
          }`
        );
      }
      inputData.consequenceStatement = stmt.join('\n');
    } catch (error) {
      console.error(error);
    } finally {
      let component = this.modalService.showComponent<RegisterRiskComponent>(
        RegisterRiskComponent,
        { inputData }
      );
      this.sidebarService.close();
      component.createEvent.subscribe((event) => {
        if (event.create) {
          this._persistRegisteredRisk(event.model, inputData);
        }
      });
    }
  }

  upload($event: FileUploadEvent) {
    console.log($event);
  }

  _persistRegisteredRisk(
    data: RiskRegisterDetails,
    inputData: RiskRegistryInputs
  ) {
    let risksNum: string = `M-L ${(
      this.riskWizardService.allRisks$.value.length + 1
    )
      .toString()
      .padStart(3, '0')}`;

    const infrastructures: InfrastructureDetails = {} as InfrastructureDetails;

    data.infrastructure.forEach((infDetail) => {
      const key: string = Object.keys(infDetail)[0];
      const infrastructure: Infrastructure = infDetail[key];
      const impactedAreas: ImpactAreaRisDetails[] = [];
      infrastructure.impactAreaRiskDetails.forEach(
        (ia: ImpactAreaRiskDetails) => {
          const key: string = Object.keys(ia)[0];
          const impactAreaRisk: ImpactAreaRisk = ia[Object.keys(ia)[0]];
          impactedAreas.push({
            impactArea: key,
            consequence: impactAreaRisk.consequence,
            structures: impactAreaRisk.structures,
            controlStrength: impactAreaRisk.controlStrength,
            controlExpediency: impactAreaRisk.controlExpediency,
            confidenceLevel: impactAreaRisk.confidenceLevel,
            natureOfImpactTo: impactAreaRisk.natureOfImpactTo,
            existingControls: impactAreaRisk.existingControls,
            controlEffectiveness: convertToTitleCase(
              impactAreaRisk.controlEffectiveness
            ),
            likelihoodAfterControl: convertToTitleCase(
              impactAreaRisk.likelihoodAfterControl
            ),
            risk: convertToTitleCase(impactAreaRisk.risk),
            priority: impactAreaRisk.priority,
          });
        }
      );

      infrastructures[key] = {
        data: inputData.infrastructure[key].data,
        impactArea: infrastructure.impactAreas,
        feature: infrastructure.feature,
        maxConsequence: infrastructure.maximumConsequence,
        impactedAreas: impactedAreas,
      };
    });

    const risk: RiskDetail = {
      riskRef: risksNum,
      analysisZone: inputData.analysisZone,
      consequenceStatement: data.consequence,
      event: data.event,
      locations: inputData.locations,
      selectedFeature: inputData.selectedFeature,
      selectedCoordinates: inputData.selectedCoordinates,
      hazardStatement: data.hazard,
      hazardType: inputData.type,
      infrastructures: infrastructures,
      likelihood: data.eventLikelihood,
      treatments: [],
    };

    this.riskWizardService.persistData(JSON.stringify(risk, null, 1));
  }
}
