import { Injectable, Signal, computed, effect } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, UrlTree } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';
import { RoutesExtended } from '../../../sidebar-types';

export type HeaderRouteData = {
  route: string;
  title: string;
  backButton?: boolean;
  backRoute?: string | Array<string>;
  children?: HeaderChildRouteData[];
};

export type HeaderChildRouteData = {
  route: string;
  title: string;
  backButton?: boolean;
  backRoute?: string | Array<string>;
  params?: any;
};

@Injectable()
export class HeaderService {
  routes: HeaderRouteData[];

  private navigationEvent: Signal<any>;

  private routePrefix = '';

  public headerDetails: Signal<HeaderRouteData | HeaderChildRouteData | null>;

  constructor(private router: Router) {
    this.navigationEvent = toSignal(
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      ),
      {}
    );

    this.headerDetails = computed(() => {
      return this.navigationEvent() ? this.isOnRouteOrChild(this.routes) : null;
    });
  }

  setRouteConfig(mapRoutes: RoutesExtended) {
    this.routes = mapRoutes
      .filter((route) => route.data != null && route.data.header != null)
      .map((route) => route.data.header);
  }

  setRoutePrefix(prefix: string) {
    this.routePrefix = prefix;
  }

  isOnRouteOrChild(
    routes: HeaderRouteData[]
  ): HeaderRouteData | HeaderChildRouteData | null {
    const currentUrlTree: UrlTree = this.router.parseUrl(this.router.url);
    const currentRoute: string[] =
      currentUrlTree.root.children['primary'].segments.map(
        (segment) => segment.path
      ) ?? [];

    for (let routeDefinition of routes) {
      if (currentRoute.includes(routeDefinition.route)) {
        if (routeDefinition.children) {
          let childWithParams = this.GetDynamicChild(
            currentRoute,
            routeDefinition
          );

          if (childWithParams) {
            return childWithParams;
          }

          let childRoute = routeDefinition.children.find(
            (childRoute) =>
              !childRoute.route.startsWith(':') &&
              currentRoute.includes(childRoute.route)
          );

          if (childRoute) {
            return childRoute;
          }
        }
        return routeDefinition;
      } else if (routeDefinition.route.includes(':id')) {
        let selector = routeDefinition.route.split('/')[0];

        const isBaseRoute = new RegExp(
          `.*\\/${selector}\\/([a-zA-Z0-9:-]+)(?![\\/])$`
        );
        const isChildRoute = new RegExp(
          `.*\\/${selector}\\/([a-zA-Z0-9:-]+).*\\/`
        );
        const backRouteRegex = new RegExp(
          `(?![\\/])${selector}\\/([a-zA-Z0-9:-]+)`
        );

        if (isBaseRoute.test(currentRoute.join('/'))) {
          return routeDefinition;
        } else if (isChildRoute.test(currentRoute.join('/'))) {
          if (routeDefinition.children) {
            let childWithParams = this.GetDynamicChild(
              currentRoute,
              routeDefinition
            );

            if (childWithParams) {
              return childWithParams;
            }

            let childRoute = routeDefinition.children.find(
              (childRoute) =>
                !childRoute.route.startsWith(':') &&
                currentRoute.includes(childRoute.route)
            );

            if (childRoute && currentRoute != null) {
              childRoute.backRoute = currentRoute
                .join('/')
                .match(backRouteRegex)[0]
                .split('/');
              return childRoute;
            }
          }
        }
      }
    }
    return null;
  }

  navigateToParent(parentRoute: any) {
    if (parentRoute !== null) {
      this.router.navigate([`${this.routePrefix}/`, ...parentRoute]);
    }
  }

  private GetDynamicChild(currentRoute: any, routeDefinition: HeaderRouteData) {
    const dynamicChildRoute = routeDefinition?.children?.find((childRoute) =>
      childRoute.route.startsWith(':')
    );

    if (
      dynamicChildRoute &&
      currentRoute.length > currentRoute.indexOf(routeDefinition.route) + 1 &&
      currentRoute[currentRoute.length - 1] !== ''
    ) {
      dynamicChildRoute.params = currentRoute[currentRoute.length - 1];
      return dynamicChildRoute;
    } else {
      return null;
    }
  }
}
