import {
  Component,
  NgZone,
  OnInit,
  Signal,
  ViewChild,
  computed,
  effect,
  inject,
  input,
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
  RouterModule,
  RouterOutlet,
} from '@angular/router';

import { SidebarService } from './data-access/sidebar.service';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { Observable, combineLatest, map, tap } from 'rxjs';
import { HeaderComponent } from './features/header/header.component';
import { CommonModule } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { RoutesExtended } from './sidebar-types';
import { TooltipDirective } from '../../../../shared/directives/tooltip/tooltip.directive';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    CommonModule,
    TooltipDirective,
    MatSidenavModule,
  ],
})
export class SidebarComponent implements OnInit {
  @ViewChild('sideNav', { static: true }) drawer: MatDrawer;

  routeConfig = input.required<RoutesExtended>();
  routePrefix = input.required<string>();

  routeConfigEffect = effect(
    () => {
      this.sidebarService.setSidebarRoutes(this.routeConfig());
    },
    { allowSignalWrites: true }
  );

  protected readonly breakpointObserver: BreakpointObserver =
    inject(BreakpointObserver);

  isSmallScreen$: Observable<boolean>;

  isSmallScreen: Signal<boolean>;
  display;

  constructor(
    private zone: NgZone,
    private router: Router,
    public route: ActivatedRoute,
    public sidebarService: SidebarService
  ) {
    this.isSmallScreen$ = this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(map((state: BreakpointState) => state.matches));

    this.isSmallScreen = toSignal(this.isSmallScreen$, { initialValue: false });

    this.display = computed(() => {
      return this.isSmallScreen() ? 'grid' : 'grid';
    });
  }

  ngOnInit(): void {
    this.sidebarService.setDrawer(this.drawer);
  }

  openPage(page) {
    if (
      this.router.url.startsWith(
        `${this.routePrefix()}/${page.route.replace(':id', '')}`
      )
    ) {
      if (this.drawer.opened) {
        this.router.navigate([this.routePrefix()]);
        page.active = false;
      } else {
        this.sidebarService.open();
      }
    } else {
      let urlSegments = this.router.url.split('/');

      let activePage = this.sidebarService
        .getPageMap()
        .get(urlSegments[urlSegments.length - 1]);

      if (activePage) activePage.active = false;

      this.router.navigate([`${this.routePrefix()}/${page.route}`]);
      page.active = true;
    }
  }
}
