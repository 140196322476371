import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { AppTerms } from '../models/app-terms.model';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  readonly _KEY: string = 'terms';

  getDevTermsAccepted() {
    const terms: AppTerms = JSON.parse(localStorage.getItem(this._KEY));

    // using domain until environment includes test flag
    if (!environment.production) {
      if (terms && terms.expiry && terms.accepted) {
        if (terms.expiry > Date.now()) {
          return of(true);
        } else {
          localStorage.removeItem(this._KEY);
          return of(false);
        }
      } else {
        return of(false);
      }
    }
    return of(true);
  }

  // set remember choice and start expiry from midnight
  setDevTermsAccepted(rememberChoice: boolean) {
    if (rememberChoice) {
      const expires = new Date();
      expires.setDate(expires.getDate() + 7);
      expires.setHours(0, 0, 0, 0);

      const terms: AppTerms = { accepted: true, expiry: expires.getTime() };
      localStorage.setItem(this._KEY, JSON.stringify(terms));
    }
  }
}
