export class CreateEvent<T> {
  create: boolean;
  model?: T;

  constructor(create, model: T | null = null) {
    this.create = create;
    this.model = model;
  }

  static create<U>(model: U): CreateEvent<U> {
    return new CreateEvent(true, model);
  }

  static cancel<U>(): CreateEvent<U> {
    return new CreateEvent<U>(false, null);
  }
}
