<div class="title-bar">
  <div class="title">Risk Treatment</div>
  <div class="window-controls">
    <button (click)="cancel()" ><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content">
  <div class="grid grid-flow-row-dense grid-cols-8 grid-rows-1 space-x-1 mx-2 mt-4 row-span-12">
    <p-floatLabel class="col-span-2">
      <input  type="text" readonly disabled="true" class="readonly-input" pInputText
              [(ngModel)]="risk.riskRef" id="riskRef"/>
      <label for="riskRef">Risk Ref</label>
    </p-floatLabel>

    <p-floatLabel class="col-span-2">
      <input  type="text" readonly disabled="true" class="readonly-input" pInputText
              [(ngModel)]="risk.hazardType" id="hazardType" />
      <label for="hazardType">Hazard Type</label>
    </p-floatLabel>

    <p-floatLabel class="col-span-2">
      <input  type="text" readonly disabled="true" class="readonly-input" pInputText
              [(ngModel)]="risk.analysisZone" id="analysisZone"/>
      <label for="analysisZone">Analysis Zone</label>
    </p-floatLabel>

    <p-floatLabel class="col-span-2">
      <input  type="text" readonly disabled="true" class="readonly-input" pInputText
              [ngModel]="riskInfrastructures.join(', ')" id="infrastructure" />
      <label for="infrastructure">Infrastructure</label>
    </p-floatLabel>
  </div>
  <div class="grid grid-flow-row-dense grid-cols-8 grid-rows-1 space-x-1 mx-2 mt-8 row-span-12">
    <p-floatLabel class="col-span-4">
        <textarea  readonly disabled="true" rows="2" class="readonly-input" [cols]="5" pInputTextarea
                   [ngModel]="risk.hazardStatement" id="hs">
        </textarea>
      <label for="hs">Risk Statement part A (Hazard)</label>
    </p-floatLabel>

    <p-floatLabel class="col-span-4">
        <textarea  readonly disabled="true"  rows="2" class="readonly-input" [cols]="5" pInputTextarea
                   [ngModel]="risk.consequenceStatement" id="consequenceStatement">
        </textarea>
      <label for="consequenceStatement">Risk Statement part B (Consequence)</label>
    </p-floatLabel>
  </div>
  <form [formGroup]="form">
    <div class="grid grid-flow-row-dense grid-cols-12 grid-rows-1 space-x-1 mx-2 mt-6">
      <p-floatLabel class="col-span-2">
        <p-dropdown formControlName="infrastructure" [options]="riskInfrastructures" id="infrastructuresS"
                    [style]="{ width: '100%', border: 'solid 1px black' }"/>
        <label for="infrastructuresS">Lifeline</label>
      </p-floatLabel>
      <p-floatLabel class="col-span-2">
        <input  type="text" class="readonly-input" pInputText [disabled]="true" readonly formControlName="feature"
                id="feature"
                [style]="{ height: '50px', width: '100%', border: 'solid 1px black', background: '#efefef', padding: '5px 10px'}"/>
        <label for="feature">Feature Type</label>
      </p-floatLabel>
      <p-floatLabel class="col-span-2">
        @if(impactedAreas().length === 1) {
          <p-dropdown formControlName="impactArea" [options]="impactedAreas()" id="impactAreaS"
                      [style]="{ width: '100%', border: 'solid 1px black' }"/>
          <label for="impactAreaS">Domain</label>
        }
        @else {
          <p-multiSelect formControlName="impactArea" [options]="impactedAreas()" id="impactAreaM"
                         [style]="{ width: '100%', border: 'solid 1px black' }"/>
          <label for="impactAreaM">Domain</label>
        }
      </p-floatLabel>

      <p-floatLabel class="col-span-6">
        <p-multiSelect formControlName="structures" class="multiselect-custom-virtual-scroll"
                       [virtualScroll]="true" [virtualScrollItemSize]="25"
                       [options]="structures()" id="structures"
                       [style]="{ width: '100%', border: 'solid 1px black' }">
          <ng-template let-value pTemplate="selectedItems">
            <div class="inline-flex align-items-center gap-2 px-1" *ngFor="let option of value">
              <div>{{ option.displayName }},</div>
            </div>
            @if (!value || value.length === 0) {
              <div>Select ...</div>
            }
          </ng-template>
          <ng-template let-props pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ props.displayName }}</div>
            </div>
          </ng-template>
          <ng-template pTemplate="footer">
            <div class="py-2 px-3 flex justify-center">
              <button (click)="selectOnMap()"
                      class="bg-sky-600 hover:bg-sky-800 text-white py-2 px-4 border border-sky-600 rounded">
                Select on Map
              </button>
            </div>
          </ng-template>
        </p-multiSelect>
        <label for="structures">Structures</label>
      </p-floatLabel>
    </div>
    <div class="grid grid-flow-row-dense grid-cols-9 grid-rows-1 space-x-1 mx-2 mt-7">
      <p-floatLabel class="col-span-3">
        <p-dropdown formControlName="options" [options]="RiskWizard.TREATMENT" id="options"
                    [style]="{ width: '100%', border: 'solid 1px black' }"/>
        <label for="options">Treatment Options</label>
      </p-floatLabel>

      <p-floatLabel class="col-span-3">
        <p-autoComplete formControlName="strategies" [suggestions]="filteredStrategies" (completeMethod)="filterStrategies($event)"
                        id="strategies" [style]="{ width: '100%' }"/>
        <label for="strategies">Risk Treatment Strategies</label>
      </p-floatLabel>
    </div>
    <div class="grid grid-flow-row-dense grid-cols-9 grid-rows-1 space-x-1 mx-2 mt-7">
      <p-floatLabel class="col-span-3">
                    <textarea  variant="outlined" rows="2" class="txt-area" [cols]="5" pInputTextarea
                               formControlName="actions" [autoResize]="true" id="actions">
                    </textarea>
        <label for="actions">Risk Treatment Actions</label>
      </p-floatLabel>
      <p-floatLabel class="col-span-3">
        <textarea  variant="outlined" rows="2" class="txt-area" [cols]="5" pInputTextarea
                   formControlName="notes" [autoResize]="true" id="notes">
        </textarea>
        <label for="notes">Notes</label>
      </p-floatLabel>
      <p-floatLabel class="col-span-3">
        <textarea  variant="outlined" rows="2" class="txt-area" [cols]="5" pInputTextarea
                   formControlName="referenceMaterial" [autoResize]="true" id="referenceMaterial">
        </textarea>
        <label for="referenceMaterial">Reference Material</label>
      </p-floatLabel>
    </div>
    <div class="grid grid-flow-row-dense grid-cols-9 grid-rows-1 space-x-1 mx-2 mt-6">
      <p-floatLabel class="col-span-3">
        <p-multiSelect formControlName="prevention" [options]="RiskWizard.PREV_PREP_RESP" id="prevention"
                       [style]="{ width: '100%', border: 'solid 1px black' }"/>
        <label for="prevention">Prevention/Mitigation Management Agency (Ref TEMP)</label>
      </p-floatLabel>

      <p-floatLabel class="col-span-3">
        <p-multiSelect formControlName="preparedness" [options]="RiskWizard.PREV_PREP_RESP" id="preparedness"
                       [style]="{ width: '100%', border: 'solid 1px black' }"/>
        <label for="preparedness">Preparedness Management Agency (Ref TEMP)</label>
      </p-floatLabel>

      <p-floatLabel class="col-span-3">
        <p-multiSelect formControlName="response" [options]="RiskWizard.PREV_PREP_RESP" id="response"
                        [style]="{ width: '100%', border: 'solid 1px black' }"/>
        <label for="response">Response Management Agency (Ref M-L EMP & TEMP)</label>
      </p-floatLabel>
    </div>
  </form>
</div>
<div class="footer mt-3">
  <button class="bg-slate-400 hover:bg-slate-600 text-white py-2 px-4 border border-slate-400 rounded" (click)="cancel()">Cancel</button>
  <button class="bg-sky-600 hover:bg-sky-800 text-white py-2 px-4 border border-sky-600 rounded" (click)="submit()">
    <span class="fa fa-save mr-1"></span>Submit
  </button>
</div>
