import { Routes } from '@angular/router';
import { LayersPageComponent } from '../features/layers-page/layers-page.component';
import { RoutesExtended } from '../ui/sidebar/sidebar-types';
import { MapComponent } from '../map/map.component';
import { ProfilePageComponent } from '../features/profile-page/profile-page.component';
import { RiskWizardComponent } from '../features/risk-wizard-page/risk-wizard/risk-wizard.component';
import { CatalogueComponent } from '../features/catalogue-page/catalogue/catalogue.component';

export const MapChildRoutes: RoutesExtended = [
  {
    path: 'catalogue',
    component: CatalogueComponent,
    data: {
      sidebar: {
        route: 'catalogue',
        title: 'Catalogue',
        className: 'fa fa-book-open',
        position: 1,
        active: false,
        disabled: false,
      },
      header: {
        route: 'catalogue',
        title: 'Catalogue',
      },
    },
  },
  {
    path: 'layers',
    component: LayersPageComponent,
    data: {
      sidebar: {
        route: 'layers',
        title: 'Layer Management',
        className: 'fa fa-layer-group',
        position: 2,
        active: false,
        disabled: false,
      },
      header: {
        route: 'layers',
        title: 'Layer Management',
      },
    },
  },
  {
    path: 'profile',
    component: ProfilePageComponent,
    data: {
      sidebar: {
        route: 'profile',
        title: 'Profile',
        className: 'fa fa-cog',
        position: 5,
        active: false,
        disabled: false,
      },
      header: {
        route: 'profile',
        title: 'Profile',
      },
    },
  },
  {
    path: 'risk-wizard',
    component: RiskWizardComponent,
    data: {
      sidebar: {
        route: 'risk-wizard',
        title: 'Risk Wizard',
        className: 'fa-solid fa-chart-simple',
        position: 3,
        active: false,
        disabled: false,
      },
      header: {
        route: 'risk-wizard',
        title: 'Risk Wizard',
      },
    },
  },
];

export const routes: Routes = [
  {
    path: '',
    component: MapComponent,
    children: MapChildRoutes,
  },
];
