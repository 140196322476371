import {
  Consequence,
  ControlEffectivenessMatrix,
  FeatureDetail,
  LikelihoodAfterControlMatrix,
  NameState,
  NameType,
  PriorityLevel,
  RiskControlMatrix,
  RiskMatrix,
} from './hazard-types.model';

export class RiskWizard {
  static readonly ALL: NameState[] = [
    { name: 'Biosecurity', disabled: false },
    { name: 'Coastal Erosion', disabled: false },
    { name: 'Coastal Inundation - storm tide', disabled: false },
    { name: 'Earthquake', disabled: false },
    { name: 'Energy Supply Emergency', disabled: false },
    { name: 'Fire-bush', disabled: false },
    { name: 'Fire - structure', disabled: false },
    { name: 'Flood - dam emergency', disabled: false },
    { name: 'Flood - flash', disabled: false },
    { name: 'Flood - river', disabled: false },
    { name: 'Food Contamination', disabled: false },
    { name: 'Hazardous Material', disabled: false },
    { name: 'Hazardous Material - radiological accident ', disabled: false },
    { name: 'Heatwave', disabled: false },
    { name: 'Influenza Pandemic', disabled: false },
    { name: 'Infrastructure Failure - building collapse', disabled: false },
    {
      name: 'Infrastructure Failure - roads and bridges collapse',
      disabled: false,
    },
    { name: 'Intentional Violence', disabled: false },
    { name: 'Landslip, Landslide', disabled: false },
    { name: 'Marine Pollution', disabled: false },
    { name: 'Nuclear Powered Warships Visits', disabled: false },
    { name: 'Public Health Emergency', disabled: false },
    { name: 'Space Debris', disabled: false },
    { name: 'Storm, High Wind, Tempest', disabled: false },
    { name: 'Transport Crash - aviation', disabled: false },
    { name: 'Transport Crash - marine', disabled: false },
    { name: 'Transport Crash - railway', disabled: false },
    { name: 'Transport Crash - road vehicle', disabled: false },
    { name: 'Tsunami', disabled: false },
    { name: 'Water Supply Contamination', disabled: false },
  ];

  static readonly ANALYSIS_ZONES: NameType[] = [
    { name: 'Local Government Areas', type: 'Local Government Areas' },
    { name: 'Sub-Catchments', type: 'Sub-Catchments' },
    { name: 'Human Settlement Areas', type: 'Human Settlement Areas' },
  ];

  static readonly EVENT: string[] = ['0.5% AEP', '1% AEP', '1% CC AEP'];

  static readonly INFRASTRUCTURE: string[] = [
    'Communications sector',
    'Defence industry sector',
    'Energy sector',
    'Food, grocery, essential hardware, and commercial supplies sector',
    'Financial services and markets sector and payments systems',
    'Health care, medical sector and community/disability support service provision',
    'Education',
    'Industrial and manufacturing activities that support essential services',
    'Primary industry activities',
    'Public safety and emergency management services',
    'Transport sector',
    'Water and sewerage sector',
    'Built environment',
  ];

  static readonly FEATURE: FeatureDetail[] = [
    {
      name: 'Telecommunications asset',
      infrastructure: 'Communications sector',
    },
    { name: 'Broadcasting asset', infrastructure: 'Communications sector' },
    { name: 'Domain name system', infrastructure: 'Communications sector' },
    {
      name: 'Data storage and processing sector',
      infrastructure: 'Communications sector',
    },
    {
      name: 'Defence Essential Infrastructure',
      infrastructure: 'Defence industry sector',
    },
    { name: 'Gas asset', infrastructure: 'Energy sector' },
    { name: 'Electricity asset', infrastructure: 'Energy sector' },
    { name: 'Energy market operator asset', infrastructure: 'Energy sector' },
    { name: 'Liquid fuel asset', infrastructure: 'Energy sector' },
    {
      name: 'Supermarkets',
      infrastructure:
        'Food, grocery, essential hardware, and commercial supplies sector',
    },
    {
      name: 'Hardware shops',
      infrastructure:
        'Food, grocery, essential hardware, and commercial supplies sector',
    },
    {
      name: 'Agricultural supplies',
      infrastructure:
        'Food, grocery, essential hardware, and commercial supplies sector',
    },
    {
      name: 'Banking asset',
      infrastructure:
        'Financial services and markets sector and payments systems',
    },
    {
      name: 'Superannuation asset',
      infrastructure:
        'Financial services and markets sector and payments systems',
    },
    {
      name: 'Insurance asset',
      infrastructure:
        'Financial services and markets sector and payments systems',
    },
    {
      name: 'Financial market infrastructure asset',
      infrastructure:
        'Financial services and markets sector and payments systems',
    },
    {
      name: 'Hospital',
      infrastructure:
        'Health care, medical sector and community/disability support service provision',
    },
    {
      name: 'Doctors',
      infrastructure:
        'Health care, medical sector and community/disability support service provision',
    },
    {
      name: 'Pharmacies',
      infrastructure:
        'Health care, medical sector and community/disability support service provision',
    },
    {
      name: 'Allied health providers',
      infrastructure:
        'Health care, medical sector and community/disability support service provision',
    },
    {
      name: 'Nursing homes',
      infrastructure:
        'Health care, medical sector and community/disability support service provision',
    },
    { name: 'Schools (Public and Private)', infrastructure: 'Education' },
    { name: 'Collages', infrastructure: 'Education' },
    { name: 'University', infrastructure: 'Education' },
    { name: 'Other', infrastructure: 'Education' },
    {
      name: 'Industrial zones',
      infrastructure:
        'Industrial and manufacturing activities that support essential services',
    },
    {
      name: 'Industrial business',
      infrastructure:
        'Industrial and manufacturing activities that support essential services',
    },
    {
      name: 'Farms (by enterprise and type)',
      infrastructure: 'Primary industry activities',
    },
    { name: 'Food processing', infrastructure: 'Primary industry activities' },
    { name: 'Forestry', infrastructure: 'Primary industry activities' },
    { name: 'Forestry depots', infrastructure: 'Primary industry activities' },
    {
      name: 'Commercial machinery depot',
      infrastructure: 'Primary industry activities',
    },
    {
      name: 'SES',
      infrastructure: 'Public safety and emergency management services',
    },
    {
      name: 'TFS',
      infrastructure: 'Public safety and emergency management services',
    },
    {
      name: 'Police',
      infrastructure: 'Public safety and emergency management services',
    },
    {
      name: 'Ambulance',
      infrastructure: 'Public safety and emergency management services',
    },
    { name: 'Aviation asset', infrastructure: 'Transport sector' },
    { name: 'Bass Strait ferry', infrastructure: 'Transport sector' },
    { name: 'Bridges', infrastructure: 'Transport sector' },
    {
      name: 'Freight infrastructure asset',
      infrastructure: 'Transport sector',
    },
    { name: 'Freight services asset', infrastructure: 'Transport sector' },
    { name: 'Roads network', infrastructure: 'Transport sector' },
    { name: 'Port asset', infrastructure: 'Transport sector' },
    {
      name: 'Public transport asset (depot and routes)',
      infrastructure: 'Transport sector',
    },
    { name: 'Rail network', infrastructure: 'Transport sector' },
    {
      name: 'Taswater – sewer and water systems / service areas',
      infrastructure: 'Water and sewerage sector',
    },
    {
      name: 'Onsite waste water systems / service areas',
      infrastructure: 'Water and sewerage sector',
    },
    {
      name: 'Private - sewer / water systems',
      infrastructure: 'Water and sewerage sector',
    },
    { name: 'Buildings', infrastructure: 'Built environment' },
  ];

  static readonly SELECTION_TYPE: NameType[] = [
    { name: 'Existing Risk CSV', type: 'Upload' },
    { name: 'Hazard Specific', type: 'Hazard' },
  ];

  static readonly IMPACT_AREA: string[] = [
    'Economy',
    'Environment',
    'People',
    'Public Administration',
    'Social Setting',
  ];

  static readonly CONSEQUENCE: Consequence[] = [
    { name: 'Insignificant', value: 1 },
    { name: 'Minor', value: 2 },
    { name: 'Moderate', value: 3 },
    { name: 'Major', value: 4 },
    { name: 'Catastrophic', value: 5 },
    { name: 'Not Considered', value: 0 },
  ];

  static readonly CONFIDENCE: string[] = [
    'Highest',
    'High',
    'Moderate',
    'Low',
    'Lowest',
  ];

  static readonly TREATMENT: string[] = [
    'Treatment',
    'Further analysis',
    'Monitor and review',
  ];

  static readonly TREATMENT_STRATEGY: string[] = [
    'Change the likelihood and consequence of the risk',
    'Avoiding the risk',
    'Removing the risk source',
    'Change the consequence of the risk',
    'Change the likelihood of the risk',
    'Retain the risk by informed decision',
    'Sharing the risk',
  ];

  static readonly PREV_PREP_RESP: string[] = [
    'Council',
    'DPIPWE',
    'TFS',
    'PWS',
    'STT',
    'SES',
    'State Growth',
    'MRT',
    'Biosecurity Tas',
    'Infrastructure Tas',
    'DHHS',
    'Public Health Services',
    'Traffic Infrastructure Services',
    'EPA',
    'Commonwealth',
    'MAST',
    'TasRail',
    'DPFEM',
    'DoJ (Land-use Planning)',
    'DPAC',
    'TASPOL',
    'Hydro Tas',
    'TasWater',
    'TasIrrigation',
    'Airservices Australia',
  ];

  static readonly LIKELIHOOD_LEVEL: string[] = [
    'Almost Certain',
    'Likely',
    'Unlikely',
    'Rare',
    'Very Rare',
    'Extremely Rare',
  ];

  static readonly CONTROL_STRENGTH_EXPEDIENCY: string[] = [
    'Very low',
    'Low',
    'Medium',
    'High',
  ];

  static readonly CTRL_EFFECTIVE_MATRIX: ControlEffectivenessMatrix = {
    High: {
      'VERY LOW': { value: 'LOW', colour: '#f57c00' }, // Orange
      LOW: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      MEDIUM: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      HIGH: { value: 'HIGH', colour: '#8bc34a' }, // Green
    },
    Medium: {
      'VERY LOW': { value: 'LOW', colour: '#f57c00' }, // Orange
      LOW: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      MEDIUM: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      HIGH: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
    },
    Low: {
      'VERY LOW': { value: 'VERY LOW', colour: '#d32f2f' }, // Red
      LOW: { value: 'LOW', colour: '#f57c00' }, // Orange
      MEDIUM: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      HIGH: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
    },
    'Very low': {
      'VERY LOW': { value: 'VERY LOW', colour: '#d32f2f' }, // Red
      LOW: { value: 'VERY LOW', colour: '#d32f2f' }, // Red
      MEDIUM: { value: 'LOW', colour: '#f57c00' }, // Orange
      HIGH: { value: 'LOW', colour: '#f57c00' }, // Orange
    },
  };

  static readonly LIKELIHOOD_AFTER_CTRL_MATRIX: LikelihoodAfterControlMatrix = {
    'Almost Certain': {
      'VERY LOW': { value: 'ALMOST CERTAIN', colour: '#d32f2f' }, // Red
      LOW: { value: 'ALMOST CERTAIN', colour: '#d32f2f' }, // Red
      MEDIUM: { value: 'LIKELY', colour: '#f57c00' }, // Orange
      HIGH: { value: 'LIKELY', colour: '#f57c00' }, // Orange
    },
    Likely: {
      'VERY LOW': { value: 'LIKELY', colour: '#f57c00' }, // Orange
      LOW: { value: 'LIKELY', colour: '#f57c00' }, // Orange
      MEDIUM: { value: 'UNLIKELY', colour: '#fbc02d' }, // Yellow
      HIGH: { value: 'UNLIKELY', colour: '#fbc02d' }, // Yellow
    },
    Unlikely: {
      'VERY LOW': { value: 'UNLIKELY', colour: '#fbc02d' }, // Yellow
      LOW: { value: 'UNLIKELY', colour: '#fbc02d' }, // Yellow
      MEDIUM: { value: 'UNLIKELY', colour: '#fbc02d' }, // Yellow
      HIGH: { value: 'RARE', colour: '#8bc34a' }, // Green
    },
    Rare: {
      'VERY LOW': { value: 'RARE', colour: '#8bc34a' }, // Green
      LOW: { value: 'RARE', colour: '#8bc34a' }, // Green
      MEDIUM: { value: 'RARE', colour: '#8bc34a' }, // Green
      HIGH: { value: 'VERY RARE', colour: '#29b6f6' }, // Light Blue
    },
    'Very Rare': {
      'VERY LOW': { value: 'VERY RARE', colour: '#29b6f6' }, // Light Blue
      LOW: { value: 'VERY RARE', colour: '#29b6f6' }, // Light Blue
      MEDIUM: { value: 'VERY RARE', colour: '#29b6f6' }, // Light Blue
      HIGH: { value: 'VERY RARE', colour: '#29b6f6' }, // Light Blue
    },
    'Extremely Rare': {
      'VERY LOW': { value: 'EXTREMELY RARE', colour: '#02Transport sectorbd' }, // Blue
      LOW: { value: 'EXTREMELY RARE', colour: '#02Transport sectorbd' }, // Blue
      MEDIUM: { value: 'EXTREMELY RARE', colour: '#02Transport sectorbd' }, // Blue
      HIGH: { value: 'EXTREMELY RARE', colour: '#02Transport sectorbd' }, // Blue
    },
  };

  static readonly RISK_MATRIX: RiskMatrix = {
    'Almost Certain': {
      INSIGNIFICANT: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      MINOR: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      MODERATE: { value: 'HIGH', colour: '#f57c00' }, // Orange
      MAJOR: { value: 'EXTREME', colour: '#d32f2f' }, // Red
      CATASTROPHIC: { value: 'EXTREME', colour: '#d32f2f' }, // Red
    },
    Likely: {
      INSIGNIFICANT: { value: 'LOW', colour: '#8bc34a' }, // Green
      MINOR: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      MODERATE: { value: 'HIGH', colour: '#f57c00' }, // Orange
      MAJOR: { value: 'EXTREME', colour: '#d32f2f' }, // Red
      CATASTROPHIC: { value: 'EXTREME', colour: '#d32f2f' }, // Red
    },
    Unlikely: {
      INSIGNIFICANT: { value: 'LOW', colour: '#8bc34a' }, // Green
      MINOR: { value: 'LOW', colour: '#8bc34a' }, // Green
      MODERATE: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      MAJOR: { value: 'HIGH', colour: '#f57c00' }, // Orange
      CATASTROPHIC: { value: 'EXTREME', colour: '#d32f2f' }, // Red
    },
    Rare: {
      INSIGNIFICANT: { value: 'VERY LOW', colour: '#29b6f6' }, // Light Blue
      MINOR: { value: 'LOW', colour: '#8bc34a' }, // Green
      MODERATE: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      MAJOR: { value: 'HIGH', colour: '#f57c00' }, // Orange
      CATASTROPHIC: { value: 'HIGH', colour: '#f57c00' }, // Orange
    },
    'Very Rare': {
      INSIGNIFICANT: { value: 'VERY LOW', colour: '#29b6f6' }, // Light Blue
      MINOR: { value: 'VERY LOW', colour: '#29b6f6' }, // Light Blue
      MODERATE: { value: 'LOW', colour: '#8bc34a' }, // Green
      MAJOR: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      CATASTROPHIC: { value: 'HIGH', colour: '#f57c00' }, // Orange
    },
    'Extremely rare': {
      INSIGNIFICANT: { value: 'VERY LOW', colour: '#29b6f6' }, // Light Blue
      MINOR: { value: 'VERY LOW', colour: '#29b6f6' }, // Light Blue
      MODERATE: { value: 'LOW', colour: '#8bc34a' }, // Green
      MAJOR: { value: 'MEDIUM', colour: '#fbc02d' }, // Yellow
      CATASTROPHIC: { value: 'HIGH', colour: '#f57c00' }, // Orange
    },
  };

  static readonly RISK_CTRL_MATRIX: RiskControlMatrix = {
    High: {
      controlStrength:
        'Control is highly effective in reducing the level of risk',
      controlExpediency: ['The control is frequently applied.'],
    },
    Medium: {
      controlStrength: 'Control is effective in reducing the level of risk',
      controlExpediency: [
        'The control is infrequently applied and is outside of the operators’ everyday experience.',
        'The use of the control has been foreseen and plans for its application have been prepared and tested.',
        'Some extraordinary cost may be required to apply the control.',
      ],
    },
    Low: {
      controlStrength: 'Control has some effect in reducing the level of risk',
      controlExpediency: [
        'The control is applied rarely and operators may not have experienced using it.',
        'The use of the control may have been foreseen and plans for its application may have been considered, but it is not part of normal operational protocols and has been tested.',
        'Extraordinary cost is required to apply the control, which may be difficult to obtain.',
      ],
    },
    'Very low': {
      controlStrength:
        'Control has almost no effect in reducing the level of risk',
      controlExpediency: [
        'Application of the control is outside of the experience and planning of operators, with no effective procedures or plans for its operation.',
        'It has not been foreseen that the control will ever need to be used.',
        'The application of the control requires significant cost over and above existing resources, and the cost will most likely be objected to by a number of stakeholders.',
      ],
    },
  };

  static PRIORITY_LEVELS: PriorityLevel = {
    Highest: {
      Insignificant: {
        'Almost Certain': { rating: 4, colour: '#eae7f0' },
        Likely: { rating: 5, colour: '#dbeab5' },
        Unlikely: { rating: 5, colour: '#dbeab5' },
        Rare: { rating: 5, colour: '#dbeab5' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Minor: {
        'Almost Certain': { rating: 4, colour: '#eae7f0' },
        Likely: { rating: 4, colour: '#eae7f0' },
        Unlikely: { rating: 5, colour: '#dbeab5' },
        Rare: { rating: 5, colour: '#dbeab5' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Moderate: {
        'Almost Certain': { rating: 3, colour: '#cfbedd' },
        Likely: { rating: 4, colour: '#eae7f0' },
        Unlikely: { rating: 4, colour: '#eae7f0' },
        Rare: { rating: 5, colour: '#dbeab5' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Major: {
        'Almost Certain': { rating: 2, colour: '#a68bbf' },
        Likely: { rating: 2, colour: '#a68bbf' },
        Unlikely: { rating: 3, colour: '#cfbedd' },
        Rare: { rating: 3, colour: '#cfbedd' },
        'Very Rare': { rating: 4, colour: '#eae7f0' },
        'Extremely Rare': { rating: 4, colour: '#eae7f0' },
      },
      Catastrophic: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 2, colour: '#a68bbf' },
        Unlikely: { rating: 2, colour: '#a68bbf' },
        Rare: { rating: 3, colour: '#cfbedd' },
        'Very Rare': { rating: 3, colour: '#cfbedd' },
        'Extremely Rare': { rating: 4, colour: '#eae7f0' },
      },
    },
    High: {
      Insignificant: {
        'Almost Certain': { rating: 4, colour: '#eae7f0' },
        Likely: { rating: 4, colour: '#eae7f0' },
        Unlikely: { rating: 5, colour: '#dbeab5' },
        Rare: { rating: 5, colour: '#dbeab5' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Minor: {
        'Almost Certain': { rating: 3, colour: '#cfbedd' },
        Likely: { rating: 4, colour: '#eae7f0' },
        Unlikely: { rating: 4, colour: '#eae7f0' },
        Rare: { rating: 5, colour: '#dbeab5' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Moderate: {
        'Almost Certain': { rating: 2, colour: '#a68bbf' },
        Likely: { rating: 3, colour: '#cfbedd' },
        Unlikely: { rating: 3, colour: '#cfbedd' },
        Rare: { rating: 4, colour: '#eae7f0' },
        'Very Rare': { rating: 4, colour: '#eae7f0' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Major: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 2, colour: '#a68bbf' },
        Unlikely: { rating: 2, colour: '#a68bbf' },
        Rare: { rating: 3, colour: '#cfbedd' },
        'Very Rare': { rating: 3, colour: '#cfbedd' },
        'Extremely Rare': { rating: 4, colour: '#eae7f0' },
      },
      Catastrophic: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 2, colour: '#a68bbf' },
        Rare: { rating: 2, colour: '#a68bbf' },
        'Very Rare': { rating: 3, colour: '#cfbedd' },
        'Extremely Rare': { rating: 3, colour: '#cfbedd' },
      },
    },
    Moderate: {
      Insignificant: {
        'Almost Certain': { rating: 3, colour: '#cfbedd' },
        Likely: { rating: 4, colour: '#eae7f0' },
        Unlikely: { rating: 4, colour: '#eae7f0' },
        Rare: { rating: 5, colour: '#dbeab5' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Minor: {
        'Almost Certain': { rating: 3, colour: '#cfbedd' },
        Likely: { rating: 3, colour: '#cfbedd' },
        Unlikely: { rating: 4, colour: '#eae7f0' },
        Rare: { rating: 4, colour: '#eae7f0' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Moderate: {
        'Almost Certain': { rating: 2, colour: '#a68bbf' },
        Likely: { rating: 2, colour: '#a68bbf' },
        Unlikely: { rating: 3, colour: '#cfbedd' },
        Rare: { rating: 3, colour: '#cfbedd' },
        'Very Rare': { rating: 4, colour: '#eae7f0' },
        'Extremely Rare': { rating: 4, colour: '#eae7f0' },
      },
      Major: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 2, colour: '#a68bbf' },
        Rare: { rating: 2, colour: '#a68bbf' },
        'Very Rare': { rating: 3, colour: '#cfbedd' },
        'Extremely Rare': { rating: 3, colour: '#cfbedd' },
      },
      Catastrophic: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 1, colour: '#8267a5' },
        Rare: { rating: 2, colour: '#a68bbf' },
        'Very Rare': { rating: 2, colour: '#a68bbf' },
        'Extremely Rare': { rating: 3, colour: '#cfbedd' },
      },
    },
    Low: {
      Insignificant: {
        'Almost Certain': { rating: 3, colour: '#cfbedd' },
        Likely: { rating: 3, colour: '#cfbedd' },
        Unlikely: { rating: 4, colour: '#eae7f0' },
        Rare: { rating: 4, colour: '#eae7f0' },
        'Very Rare': { rating: 5, colour: '#dbeab5' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Minor: {
        'Almost Certain': { rating: 2, colour: '#a68bbf' },
        Likely: { rating: 3, colour: '#cfbedd' },
        Unlikely: { rating: 3, colour: '#cfbedd' },
        Rare: { rating: 4, colour: '#eae7f0' },
        'Very Rare': { rating: 4, colour: '#eae7f0' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Moderate: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 2, colour: '#a68bbf' },
        Unlikely: { rating: 2, colour: '#a68bbf' },
        Rare: { rating: 3, colour: '#cfbedd' },
        'Very Rare': { rating: 3, colour: '#cfbedd' },
        'Extremely Rare': { rating: 4, colour: '#eae7f0' },
      },
      Major: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 1, colour: '#8267a5' },
        Rare: { rating: 2, colour: '#a68bbf' },
        'Very Rare': { rating: 2, colour: '#a68bbf' },
        'Extremely Rare': { rating: 3, colour: '#cfbedd' },
      },
      Catastrophic: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 1, colour: '#8267a5' },
        Rare: { rating: 1, colour: '#8267a5' },
        'Very Rare': { rating: 2, colour: '#a68bbf' },
        'Extremely Rare': { rating: 2, colour: '#a68bbf' },
      },
    },
    Lowest: {
      Insignificant: {
        'Almost Certain': { rating: 2, colour: '#a68bbf' },
        Likely: { rating: 3, colour: '#cfbedd' },
        Unlikely: { rating: 3, colour: '#cfbedd' },
        Rare: { rating: 4, colour: '#eae7f0' },
        'Very Rare': { rating: 4, colour: '#eae7f0' },
        'Extremely Rare': { rating: 5, colour: '#dbeab5' },
      },
      Minor: {
        'Almost Certain': { rating: 2, colour: '#a68bbf' },
        Likely: { rating: 2, colour: '#a68bbf' },
        Unlikely: { rating: 3, colour: '#cfbedd' },
        Rare: { rating: 3, colour: '#cfbedd' },
        'Very Rare': { rating: 4, colour: '#eae7f0' },
        'Extremely Rare': { rating: 4, colour: '#eae7f0' },
      },
      Moderate: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 2, colour: '#a68bbf' },
        Rare: { rating: 2, colour: '#a68bbf' },
        'Very Rare': { rating: 3, colour: '#cfbedd' },
        'Extremely Rare': { rating: 3, colour: '#cfbedd' },
      },
      Major: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 1, colour: '#8267a5' },
        Rare: { rating: 1, colour: '#8267a5' },
        'Very Rare': { rating: 2, colour: '#a68bbf' },
        'Extremely Rare': { rating: 2, colour: '#a68bbf' },
      },
      Catastrophic: {
        'Almost Certain': { rating: 1, colour: '#8267a5' },
        Likely: { rating: 1, colour: '#8267a5' },
        Unlikely: { rating: 1, colour: '#8267a5' },
        Rare: { rating: 1, colour: '#8267a5' },
        'Very Rare': { rating: 1, colour: '#8267a5' },
        'Extremely Rare': { rating: 2, colour: '#a68bbf' },
      },
    },
  };
}
