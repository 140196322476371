import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Output, OutputEmitterRef, inject, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomValidators } from '../../../../utils/CustomValidators';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PasswordPopoverDirective } from '../../../../directives/password-popover.directive';
import { Result } from '../../../../events/Result';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-password-reset',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, PasswordPopoverDirective],
  templateUrl: './password-reset-self.component.html',
  styleUrl: './password-reset-self.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordResetSelfComponent {

  private readonly dialogRef: DialogRef<PasswordResetSelfComponent> = inject(DialogRef<PasswordResetSelfComponent>)
  result = output<Result<{password:string, newPassword:string}>>()

  form = new FormGroup({
    password: new FormControl(null,Validators.required),
    newPassword: new FormControl(null, [
      CustomValidators.passwordHasSpecial,
      CustomValidators.passwordHasUpper,
      Validators.minLength(8),
      Validators.required,
    ]),
    confirmPassword: new FormControl(null, Validators.required),
  },{validators: [CustomValidators.passwordsMatch('newPassword', 'confirmPassword')]});


  onSubmit()
  {
      this.result.emit(Result.ok({password: this.form.get('password').value, newPassword: this.form.get('newPassword').value}))
      this.dialogRef.close();
  }
  cancel()
  {
      this.result.emit(Result.cancel());
      this.dialogRef.close();
  }
}
