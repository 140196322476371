<div>
  <p-accordion>
    @for(group of layerService.catalogue; track group; let gI = $index) {
      <p-accordionTab [header]="group.name">
        <p-listbox [options]="group.layers" readonly="true">
          <ng-template let-layer pTemplate="item">
              {{layer.name}}
              @if (!layer.added) {
                <button class="bg-green-500 hover:bg-green-700 text-white py-2 px-2 border border-green-500 rounded w-32"
                        appTooltip="Add Layer" tooltipPosition="left" (click)="addLayer(group, gI, layer)">
                  <i class="fa-solid fa-plus"></i> Add Layer
                </button>
              }
              @else {
                <button class="bg-red-500 hover:bg-red-700 text-white py-2 px-2 border border-red-500 rounded w-30"
                        appTooltip="Remove Layer" tooltipPosition="left" (click)="removeLayer(group, gI, layer)">
                  <i class="fa-solid fa-trash"></i> Remove Layer
                </button>
              }
          </ng-template>
        </p-listbox>
      </p-accordionTab>
    }
  </p-accordion>
</div>
