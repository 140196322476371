<div class="title-bar">
  <div class="title">Register Risk {{heading}}</div>
  <div class="window-controls">
    <button (click)="cancel()" ><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content">
  <form [formGroup]="form">
    <div class="grid grid-flow-row-dense grid-cols-10 grid-rows-1 space-x-1 mx-2 mt-4 row-span-12">
      <p-floatLabel class="col-span-5">
        <textarea  variant="outlined" rows="2" class="txt-area" [cols]="5" pInputTextarea
                   formControlName="hazardStatement" [autoResize]="true" id="hs">
        </textarea>
        <label for="hs">Risk Statement part A (Hazard)</label>
      </p-floatLabel>

      <p-floatLabel class="col-span-5">
        <textarea  variant="outlined" rows="2" class="txt-area" [cols]="5" pInputTextarea
                   formControlName="consequenceStatement" [autoResize]="true" id="consequenceStatement">
        </textarea>
        <label for="consequenceStatement">Risk Statement part B (Consequence)</label>
      </p-floatLabel>
    </div>
    <div class="grid grid-flow-row-dense grid-cols-10 grid-rows-1 space-x-1 mx-2 mt-4 row-span-3">
      <p-floatLabel class="col-span-2">
        <input type="text" pInputText formControlName="event" id="event" [disabled]="true" readonly
               [style]="{ height: '50px', width: '100%', border: 'solid 1px black', background: '#efefef', padding: '5px 10px'}">
        <label for="event">Event</label>
      </p-floatLabel>
      <p-floatLabel class="col-span-3">
        <p-dropdown formControlName="likelihood" [options]="RiskWizard.LIKELIHOOD_LEVEL" id="likelihood"
                    [style]="{ width: '100%', border: 'solid 1px black' }"/>
        <label for="likelihood">Event Likelihood (AEP)</label>
      </p-floatLabel>
    </div>
    <p-accordion [multiple]="true" styleClass="mt-2">
      @for (area of infrastructureControls; track area; let idx = $index) {
        <p-accordionTab [header]="getGroupKey(area)">
          <div [formGroup]="getFormGroup(area)">
            <div [formGroupName]="getGroupKey(area)">
              <div class="grid grid-flow-row-dense grid-cols-10 grid-rows-1 space-x-1 mx-2 mt-3">
                <p-floatLabel class="col-span-2">
                  <input  type="text" class="readonly-input" pInputText [disabled]="true" readonly formControlName="feature" id="feature"
                          [style]="{ height: '50px', width: '100%', border: 'solid 1px black', background: '#efefef', padding: '5px 10px'}"/>
                  <label for="feature">Feature Type</label>
                </p-floatLabel>
                <p-floatLabel class="col-span-3">
                  <p-multiSelect formControlName="impactArea" [options]="RiskWizard.IMPACT_AREA" id="impactArea"
                  (onChange)="impactAreaChange($event, getGroupKey(area))" [style]="{ width: '100%', border: 'solid 1px black' }"/>
                  <label for="impactArea">Domain</label>
                </p-floatLabel>

                <p-floatLabel class="col-span-2">
                  <p-dropdown [(ngModel)]="maxConsequences[idx]" [disabled]="true" optionLabel="name" optionValue="value"
                              [options]="RiskWizard.CONSEQUENCE" id="maxConsequence" [ngModelOptions]="{standalone: true}"
                              [style]="{ width: '100%', border: 'solid 1px black', background: '#efefef'}"/>
                  <label for="impactArea">Maximum Consequence</label>
                </p-floatLabel>
              </div>
              @if (multipleImpactedAreas.get(getGroupKey(area)) && multipleImpactedAreas.get(getGroupKey(area)).length >= 1) {
                <p-accordion [multiple]="true" styleClass="mt-2">
                  @for (group of getImpactedAreaControls(getGroupKey(area)); track $index;) {
                    <p-accordionTab [header]="multipleImpactedAreas.get(getGroupKey(area))[$index]">
                      <div [formGroup]="riskInfoService.getFormGroup(group)">
                        <div [formGroupName]="riskInfoService.getGroupKey(group)">
                          <div class="grid grid-flow-row-dense grid-cols-12 grid-rows-1 space-x-1 mt-2">
                            <p-floatLabel class="col-span-2">
                              <p-dropdown formControlName="consequence" optionLabel="name" optionValue="value"
                                          [options]="RiskWizard.CONSEQUENCE" id="consequence"
                                          [style]="{ width: '100%', border: 'solid 1px black' }"/>
                              <label for="consequence">Consequence</label>
                            </p-floatLabel>
                            <p-floatLabel class="col-span-10">
                              <p-multiSelect formControlName="structures" class="multiselect-custom-virtual-scroll"
                                             [virtualScroll]="true" [virtualScrollItemSize]="25"
                                             [options]="getInfrastructure(getGroupKey(area))" id="structures"
                                             [style]="{ width: '100%', border: 'solid 1px black' }">
                                <ng-template let-value pTemplate="selectedItems">
                                  <div class="inline-flex align-items-center gap-2 px-1" *ngFor="let option of value">
                                    <div>{{ option.displayName }},</div>
                                  </div>
                                  @if (!value || value.length === 0) {
                                    <div>Select ...</div>
                                  }
                                </ng-template>
                                <ng-template let-props pTemplate="item">
                                  <div class="flex align-items-center gap-2">
                                    <div>{{ props.displayName }}</div>
                                  </div>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                  <div class="py-2 px-3 flex justify-center">
                                    <button (click)="selectOnMap(getGroupKey(area))"
                                      class="bg-sky-600 hover:bg-sky-800 text-white py-2 px-4 border border-sky-600 rounded">
                                      Select on Map
                                    </button>
                                  </div>
                                </ng-template>
                              </p-multiSelect>
                              <label for="structures">{{getGroupKey(area)}}</label>
                            </p-floatLabel>
                          </div>
                          <div class="grid grid-flow-row-dense grid-cols-9 grid-rows-1 space-x-1 mt-4">
                            <p-floatLabel class="col-span-3">
                              <textarea  variant="outlined" rows="2" class="txt-area" [cols]="5" pInputTextarea
                                         formControlName="existingControls" [autoResize]="true" id="existingControls">
                              </textarea>
                              <label for="existingControls">Existing Controls</label>
                            </p-floatLabel>
                            <p-floatLabel class="col-span-2">
                              <p-dropdown formControlName="ctrlStrength"
                                          [options]="RiskWizard.CONTROL_STRENGTH_EXPEDIENCY" id="ctrlStrength"
                                          [style]="{ width: '100%', border: 'solid 1px black' }"/>
                              <label for="ctrlStrength">Controls Strength</label>
                            </p-floatLabel>
                            <p-floatLabel class="col-span-2">
                              <p-dropdown formControlName="ctrlExpediency" [options]="RiskWizard.CONTROL_STRENGTH_EXPEDIENCY"
                                          id="ctrlExpediency" [style]="{ width: '100%', border: 'solid 1px black' }"/>
                              <label for="ctrlExpediency">Controls Expediency</label>
                            </p-floatLabel>
                            <p-floatLabel class="col-span-2">
                              <p-dropdown formControlName="confidenceLvl"  [options]="RiskWizard.CONFIDENCE"
                                          id="confidenceLvl" [style]="{ width: '100%', border: 'solid 1px black' }"/>
                              <label for="confidenceLvl">Confidence Level</label>
                            </p-floatLabel>
                          </div>
                          <div class="grid grid-flow-row-dense grid-cols-12 grid-rows-1 space-x-1 mt-4">
                            <p-floatLabel class="col-span-4">
                              <textarea  variant="outlined" rows="2" class="txt-area" [cols]="5" pInputTextarea
                                         formControlName="impactNature" [autoResize]="true" id="impactNature">
                              </textarea>
                              <label for="impactNature">Nature of Impact to {{multipleImpactedAreas[$index]}}</label>
                            </p-floatLabel>
                            <div class="flex flex-col items-center text-center align-middle col-start-5 col-span-2">
                              <label class="inline-block matrix-lbl p-float-label">Effectiveness</label>
                              <div class="matrix-result" [style.background-color]="riskInfoService.getCtrlEffectiveness(getGroupKey(area), group).colour">
                                <p class="matrix-value">{{riskInfoService.getCtrlEffectiveness(getGroupKey(area), group).value}}</p>
                              </div>
                            </div>
                            <div class="flex flex-col items-center text-center align-middle col-start-7 col-span-2">
                              <label class="inline-block matrix-lbl p-float-label">Likelihood</label>
                              <div  class="matrix-result" [style.background-color]="riskInfoService.getLikelihoodAfterCtrl(getGroupKey(area), group).colour">
                                <p class="matrix-value">{{riskInfoService.getLikelihoodAfterCtrl(getGroupKey(area), group).value}}</p>
                              </div>
                            </div>
                            <div class="flex flex-col items-center text-center align-middle col-start-9 col-span-2">
                              <label class="inline-block matrix-lbl p-float-label">Risk</label>
                              <div class="matrix-result" [style.background-color]="riskInfoService.getRiskLevel(getGroupKey(area), group).colour">
                                <p class="matrix-value">{{riskInfoService.getRiskLevel(getGroupKey(area), group).value}}</p>
                              </div>
                            </div>
                            <div class="flex flex-col items-center text-center align-middle col-start-11 col-span-2">
                              <label class="inline-block matrix-lbl p-float-label">Priority</label>
                              <div class="matrix-result" [style.background-color]="riskInfoService.getPriorityLevel(getGroupKey(area), group).colour">
                                <p class="matrix-value">{{riskInfoService.getPriorityLevel(getGroupKey(area), group).rating}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-accordionTab>
                  }
                </p-accordion>
              }
            </div>
          </div>
        </p-accordionTab>
      }
    </p-accordion>
  </form>
</div>
<div class="footer mt-3">
  <button class="bg-slate-400 hover:bg-slate-600 text-white py-2 px-4 border border-slate-400 rounded" (click)="cancel()">Cancel</button>
  <button class="bg-sky-600 hover:bg-sky-800 text-white py-2 px-4 border border-sky-600 rounded" (click)="submit()">
    <span class="fa fa-save mr-1"></span>Submit
  </button>
</div>
