<div class="overlay">
  @if (riskWizardService.interactionType === 'Draw') {
    <div class="grid grid-cols-3 gap-4">
      <button class="bg-red-500 hover:bg-red-700 text-white py-2 px-2 border border-red-500 rounded" (click)="cancel()">
        <span class="fa fa-times"></span>
      </button>
      <button class="bg-gray-500 hover:bg-gray-700 text-white py-2 px-2 border border-gray-500 rounded" (click)="undo()">
        <span class="fa fa-undo"></span>
      </button>
      <button class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 border border-blue-500 rounded"
              [disabled]="!riskWizardService.completePolygon"
               (click)="finished()">
        <span class="fa fa-check"></span>
      </button>
    </div>
  } @else if (riskWizardService.interactionType === 'Select') {
    @if (riskWizardService.bridgeName !== '') {
      <div class="my-2">{{riskWizardService.bridgeName}}</div>
      <p-table [value]="riskWizardService.rasterInfo" styleClass="p-datatable-striped p-datatable-lg"
               [tableStyle]="{'min-width': '15rem'}">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-sm">Layer</th>
            <th class="text-sm">Value</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-layer>
          <tr>
            <td class="p-2 text-sm" style="text-align: left;">{{layer.layer}}</td>
            <td class="p-2 text-sm" style="text-align: left;">{{layer.value}}</td>
            <td><img src="{{layer.imgUrl}}" width="15px" height="15px" alt="Tile Img"></td>
          </tr>
        </ng-template>
      </p-table>
    }
    <div class="grid grid-cols-2 gap-4">
      <button class="bg-red-500 hover:bg-red-700 text-white py-2 px-2 border border-red-500 rounded" (click)="cancel()">
        <span class="fa fa-times"></span>
      </button>
      <button class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 border border-blue-500 rounded"
              [disabled]="riskWizardService.coordinates.length == 0"
              (click)="finished()">
        <span class="fa fa-check"></span>
      </button>
    </div>
  }
</div>
