<div class="layer">
  <div class="flex items-center gap-5">
    <p-inputSwitch [ngModel]="layerService.isLayerVisible(layer())" LayerVisibilityControl [layer]="layer().layer"
                   [currentVisibleState]="layer().visible" [disabled]="!layer().canActivate" class="small-input-switch"/>

    <style-renderer #renderer [kind]="layer().kind" [style]="layer().layer.getStyle()"
      [featureProperties]="layer().featureProperties"></style-renderer>
    <span>{{ layer().title }} </span>
  </div>
  <div class="flex items-center gap-5">
    <input autocomplete="off" data-lpignore="true" #opacityInput type="range" min="0" max="1" step="0.01"
           layerOpacityControl [layer]="layer().layer" [value]="layer().opacity"/>
    <button (click)="removeLayer()">
      <span><i class="fa fa-trash"></i></span>
    </button>
  </div>
</div>
