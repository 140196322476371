import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import {
  LayersService,
  LayerState,
} from '../../data-access/services/layers.service';
import { LayerOpacityDirective } from '../../utils/layer-opacity.directive';
import { LayerVisibilityDirective } from '../../utils/layer-visibility.directive';
import { layersControl } from '../../utils/layers.control';
import { StyleRendererComponent } from '../../../../../../shared/ui/style-renderer/style-renderer.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-vector-layer',
  templateUrl: './vector-layer.component.html',
  styleUrls: ['./vector-layer.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    StyleRendererComponent,
    LayerOpacityDirective,
    LayerVisibilityDirective,
    InputSwitchModule,
    FormsModule,
  ],
})
export class VectorLayerComponent extends layersControl {
  layer = input<LayerState>();
  layerGroup = input<LayerState>();
  protected readonly layerService: LayersService = inject(LayersService);

  removeLayer() {
    this.layerService.removeLayerFromMap(this.layerGroup(), this.layer());
  }
}
