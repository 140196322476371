import {
  Component,
  effect,
  EventEmitter,
  inject,
  Inject,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { RiskWizard } from '../../../risk-wizard-page/data-access/models/risk-wizard';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  Risk,
  RiskDetail,
} from '../../../risk-wizard-page/data-access/models/risk-detail.model';
import { InputTextModule } from 'primeng/inputtext';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import { toSignal } from '@angular/core/rxjs-interop';
import { RiskWizardService } from '../../../risk-wizard-page/data-access/services/risk-wizard.service';
import { CreateEvent } from '../../../risk-wizard-page/risk-wizard/create-event';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'app-create-treatment',
  standalone: true,
  imports: [
    AccordionModule,
    DropdownModule,
    FloatLabelModule,
    FormsModule,
    InputTextareaModule,
    MultiSelectModule,
    ReactiveFormsModule,
    InputTextModule,
    AutoCompleteModule,
    NgForOf,
  ],
  templateUrl: './create-treatment.component.html',
  styleUrl: './create-treatment.component.css',
})
export class CreateTreatmentComponent {
  form: FormGroup;
  readonly risk: RiskDetail;
  protected readonly RiskWizard = RiskWizard;
  readonly riskWizardService: RiskWizardService = inject(RiskWizardService);
  private readonly dialogRef: DialogRef<CreateTreatmentComponent> = inject(
    DialogRef<CreateTreatmentComponent>
  );
  readonly formBuilder: FormBuilder = inject(FormBuilder);
  filteredStrategies: string[] = [];
  impactedAreas: WritableSignal<string[]>;
  structures: WritableSignal<any[]>;

  @Output() createEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(DIALOG_DATA) protected data: { risk: RiskDetail }) {
    this.impactedAreas = signal([]);
    this.structures = signal([]);
    this.risk = data.risk;
    this.form = this.formBuilder.group({
      infrastructure: ['', [Validators.required]],
      feature: [{ value: '', disabled: true }, [Validators.required]],
      impactArea: ['', [Validators.required]],
      structures: ['', [Validators.required]],
      options: ['', [Validators.required]],
      strategies: ['', [Validators.required]],
      actions: ['', [Validators.required]],
      notes: ['', [Validators.required]],
      referenceMaterial: ['', [Validators.required]],
      prevention: ['', [Validators.required]],
      preparedness: ['', [Validators.required]],
      response: ['', [Validators.required]],
    });

    const infrastructuresChanges = toSignal(
      this.form.get('infrastructure').valueChanges
    );

    const domainChanges = toSignal(this.form.get('impactArea').valueChanges);

    if (Object.keys(this.risk.infrastructures).length === 1) {
      this.form
        .get('infrastructure')
        .setValue(
          this.risk.infrastructures[Object.keys(this.risk.infrastructures)[0]]
        );
    }

    effect(
      () => {
        if (this.risk.infrastructures[infrastructuresChanges()]) {
          this.impactedAreas.set(
            this.risk.infrastructures[infrastructuresChanges()].impactArea
          );
          this.form
            .get('feature')
            .setValue(
              this.risk.infrastructures[infrastructuresChanges()].feature
            );
          if (
            this.risk.infrastructures[infrastructuresChanges()].impactArea
              .length === 1
          ) {
            this.form
              .get('impactArea')
              .setValue(
                this.risk.infrastructures[infrastructuresChanges()]
                  .impactArea[0]
              );
          }
        }

        if (domainChanges()) {
          console.log(domainChanges());
          this.structures.set(
            this.risk.infrastructures[
              infrastructuresChanges()
            ].impactedAreas.find((ia) => (ia.impactArea = domainChanges()))
              .structures
          );
        }
      },
      { allowSignalWrites: true }
    );
  }

  get riskInfrastructures(): string[] {
    return Object.keys(this.risk.infrastructures);
  }

  //TODO
  selectOnMap() {
    console.log('called');
  }

  cancel() {
    this.createEvent.emit(CreateEvent.cancel<Risk>());
    this.dialogRef.close();
  }

  submit() {
    const recordToUpdate: Risk = this.riskWizardService.allRisks$.value.find(
      (r) => r.data.riskRef === this.risk.riskRef
    );

    recordToUpdate.data.treatments.push({
      infrastructure: this.form.get('infrastructure').value,
      feature: this.form.get('feature').value,
      structures: this.form.get('structures').value,
      impactAreas: this.form.get('impactArea').value,
      treatmentOptions: this.form.get('options').value,
      riskTreatmentStrategies: this.form.get('strategies').value,
      riskTreatmentActions: this.form.get('actions').value,
      notes: this.form.get('notes').value,
      referenceMaterial: this.form.get('referenceMaterial').value,
      preventionMitigationManagementAgency: this.form.get('prevention').value,
      preparednessManagementAgency: this.form.get('preparedness').value,
      responseManagementAgency: this.form.get('response').value,
    });

    this.createEvent.emit(CreateEvent.create<Risk>(recordToUpdate));
    this.dialogRef.close();
  }

  filterStrategies($event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = $event.query;

    for (let i = 0; i < RiskWizard.TREATMENT_STRATEGY.length; i++) {
      let strategy = this.RiskWizard.TREATMENT_STRATEGY[i];
      if (strategy.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(strategy);
      }
    }

    this.filteredStrategies = filtered;
    /*this.treatmentStrategies.filter(
      (s) => s.toLowerCase().indexOf(query.toLowerCase()) === 0
    );*/
  }
}
