import {
  Component,
  effect,
  EventEmitter,
  Inject,
  inject,
  OnDestroy,
  Output,
  signal,
  Signal,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RiskWizard } from '../../data-access/models/risk-wizard';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ImpactAreaRiskDetails,
  InfrastructureDetail,
  RiskRegisterDetails,
} from '../../data-access/models/hazard-types.model';
import { NgForOf } from '@angular/common';
import { CreateEvent } from '../create-event';
import { RiskInfoService } from '../../../bottom-drawer/data-access/risk-info.service';
import { MultiSelectChangeEvent, MultiSelectModule } from 'primeng/multiselect';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { InputTextModule } from 'primeng/inputtext';
import { RiskRegistryInputs } from '../../data-access/models/risk-registry-inputs.model';
import {
  BridgeProperties,
  BuildingProperties,
  MultiSelectLabel,
} from '../../../../data-access/models/bridge-data.model';

@Component({
  selector: 'app-register-risk',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgForOf,
    FloatLabelModule,
    InputTextareaModule,
    DropdownModule,
    MultiSelectModule,
    AccordionModule,
    InputTextModule,
  ],
  templateUrl: './register-risk.component.html',
  styleUrl: './register-risk.component.css',
})
export class RegisterRiskComponent implements OnDestroy {
  form: FormGroup;
  heading: string = '';
  maxConsequences: string[] = [];
  protected readonly RiskWizard = RiskWizard;
  protected multipleImpactedAreas: Map<string, string[]>;

  readonly riskInfoService: RiskInfoService = inject(RiskInfoService);
  readonly formBuilder: FormBuilder = inject(FormBuilder);
  private readonly dialogRef: DialogRef<RegisterRiskComponent> = inject(
    DialogRef<RegisterRiskComponent>
  );

  @Output() createEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(DIALOG_DATA)
    protected data: {
      inputData: RiskRegistryInputs;
    }
  ) {
    this.multipleImpactedAreas = new Map();
    for (const key in data.inputData.infrastructure) {
      this.heading += `${key}: ${data.inputData.infrastructure[key].count} `;
    }

    const infrastructureFg: FormArray = this.formBuilder.array([]);
    const multiImpAreaSignals = signal<Signal<any>[]>([]);
    for (const key in data.inputData.infrastructure) {
      const fGrr: FormGroup = this.formBuilder.group({
        [key]: this.formBuilder.group({
          impactArea: ['', [Validators.required]],
          feature: [
            {
              value: data.inputData.infrastructure[key].feature,
              disabled: true,
            },
            [Validators.required],
          ],
          impactedAreas: this.formBuilder.array([]),
        }),
      });
      infrastructureFg.push(fGrr);
      multiImpAreaSignals.update((items) => [
        ...items,
        toSignal(fGrr.get([key, 'impactedAreas'])!.valueChanges),
      ]);
    }

    effect(
      () => {
        if (
          multiImpAreaSignals() &&
          !multiImpAreaSignals().some((i) =>
            Object.values(i).some((value) => value === null)
          )
        ) {
          multiImpAreaSignals().forEach((v, index) => {
            this.maxConsequences[index] =
              this.riskInfoService.getMaxConsequence(v());
          });
        }
      },
      { allowSignalWrites: true }
    );

    this.form = this.formBuilder.group({
      hazardStatement: [data.inputData.hazardStatement, [Validators.required]],
      consequenceStatement: [
        data.inputData.consequenceStatement,
        [Validators.required],
      ],
      event: [data.inputData.event, [Validators.required]],
      likelihood: ['', [Validators.required]],
      infrastructure: infrastructureFg,
    });

    this.riskInfoService.likelihood = this.form.get('likelihood').valueChanges;
  }

  ngOnDestroy(): void {
    this.riskInfoService.likelihoodState.forEach((infrastructure) => {
      infrastructure.forEach((value) => {
        value.ctrlEffectiveness$.unsubscribe();
        value.likelihoodAfterCtrl$.unsubscribe();
        value.riskLevel$.unsubscribe();
        value.priorityLevel$.unsubscribe();
        value.likelihood$.unsubscribe();
      });
    });

    this.riskInfoService.likelihoodState.clear();
  }

  get infrastructures(): FormArray {
    return this.form.get('infrastructure') as FormArray;
  }

  get infrastructureControls(): any[] {
    return this.infrastructures.controls;
  }

  getFormGroup(group: any): FormGroup {
    return group as FormGroup;
  }

  getGroupKey(group: any) {
    return Object.keys(group.controls)[0];
  }

  getImpactedAreas(key: string): FormArray {
    const frmGr: FormGroup = this.infrastructures.controls.find((c) =>
      c.get(key)
    ) as FormGroup;
    return frmGr.get(key).get('impactedAreas') as FormArray;
  }

  getImpactedAreaControls(key: string): any[] {
    return this.getImpactedAreas(key).controls;
  }

  getInfrastructure(key: string): MultiSelectLabel[] {
    const feature = this.data.inputData.infrastructure[key].feature;
    if (key === 'Transport sector' && feature === 'Bridges') {
      return this.data.inputData.infrastructure[key].data.features.map((f) => {
        const prop = f.properties as BridgeProperties;
        return {
          id: f.id,
          geometry: f.geometry,
          displayName: `${prop.bridge_num} - ${prop.bridge_name}`,
        };
      });
    } else if (key === 'Built environment' && feature === 'Buildings') {
      return this.data.inputData.infrastructure[key].data.features.map((f) => {
        const prop = f.properties as BuildingProperties;
        return {
          id: f.id,
          geometry: f.geometry,
          displayName: `${prop.build_id} (HH:${prop.hh_1pc}) ${prop.address}`,
        };
      });
    }
    return null;
  }

  //TODO
  selectOnMap(groupKey: string) {
    console.log(groupKey);
  }

  cancel(): void {
    this.createEvent.emit(CreateEvent.cancel<RiskRegisterDetails>());
    this.dialogRef.close();
  }

  submit(): void {
    let details: RiskRegisterDetails = {
      hazard: this.form.get('hazardStatement').value,
      consequence: this.form.get('consequenceStatement').value,
      event: this.form.get('event').value,
      eventLikelihood: this.form.get('likelihood').value,
      infrastructure: this._getInfrastructure(),
    };

    this.createEvent.emit(CreateEvent.create<RiskRegisterDetails>(details));
    this.dialogRef.close();
  }

  impactAreaChange($event: MultiSelectChangeEvent, key: string) {
    if (!this.multipleImpactedAreas.has(key)) {
      this.getImpactedAreas(key).push(
        this.riskInfoService.setFormGroupControlValueChanges(
          key,
          this.formBuilder,
          $event.itemValue
        )
      );
      this.multipleImpactedAreas.set(key, $event.value);
    } else {
      const isAddition = !this.multipleImpactedAreas
        .get(key)
        .includes($event.itemValue);
      if (isAddition) {
        this.getImpactedAreas(key).push(
          this.riskInfoService.setFormGroupControlValueChanges(
            key,
            this.formBuilder,
            $event.itemValue
          )
        );
      } else {
        this.riskInfoService.removeImpactedArea(
          key,
          $event.itemValue,
          this.getImpactedAreas(key)
        );
        const index = this.multipleImpactedAreas
          .get(key)
          .indexOf($event.itemValue);
        this.multipleImpactedAreas.get(key).splice(index, 1);
      }
    }
    this.multipleImpactedAreas.set(key, $event.value);
  }

  _getInfrastructure(): InfrastructureDetail[] {
    return this.infrastructures.controls.reduce(
      (inf: InfrastructureDetail[], fg: FormGroup, i: number) => {
        let key: string = Object.keys(fg.controls)[0];
        const fGroup: FormGroup = fg.controls[key] as FormGroup;
        inf.push({
          [key]: {
            impactAreas: fGroup.controls['impactArea'].value,
            feature: fGroup.controls['feature'].value,
            impactAreaRiskDetails: this._getImpactedAreaConsequenceArray(key),
            maximumConsequence: this.maxConsequences[i],
          },
        });
        return inf;
      },
      []
    );
  }

  _getImpactedAreaConsequenceArray(infKey: string): ImpactAreaRiskDetails[] {
    return this.getImpactedAreas(infKey).controls.reduce(
      (acc: ImpactAreaRiskDetails[], formGroup: FormGroup) => {
        let key: string = Object.keys(formGroup.controls)[0];
        const fGroup: FormGroup = formGroup.controls[key] as FormGroup;
        const consequence: string = this.riskInfoService.getConsequenceName(
          fGroup.controls['consequence'].value
        );
        const isNotConsidered: boolean = consequence === 'Not Considered';
        let impAreaCos: ImpactAreaRiskDetails = {
          [key]: {
            consequence: consequence,
            structures: fGroup.controls['structures'].value,
            controlStrength: fGroup.controls['ctrlStrength'].value,
            controlExpediency: fGroup.controls['ctrlExpediency'].value,
            confidenceLevel: fGroup.controls['confidenceLvl'].value,
            natureOfImpactTo: fGroup.controls['impactNature'].value,
            existingControls: fGroup.controls['existingControls'].value,
            controlEffectiveness: isNotConsidered
              ? ''
              : this.riskInfoService.likelihoodState.get(infKey).get(key)
                  .ctrlEffectiveness$.value.value,
            likelihoodAfterControl: isNotConsidered
              ? ''
              : this.riskInfoService.likelihoodState.get(infKey).get(key)
                  .likelihoodAfterCtrl$.value.value,
            risk: isNotConsidered
              ? ''
              : this.riskInfoService.likelihoodState.get(infKey).get(key)
                  .riskLevel$.value.value,
            priority: isNotConsidered
              ? ''
              : this.riskInfoService.likelihoodState
                  .get(infKey)
                  .get(key)
                  .priorityLevel$.value.rating.toString(),
          },
        };
        acc.push(impAreaCos);
        return acc;
      },
      []
    );
  }
}
