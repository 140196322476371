import { Component, inject } from '@angular/core';
import { CategoryInfo, LayerInfo } from '../data-access/catalogue-config.model';
import { KeyValuePipe, NgClass } from '@angular/common';
import { TooltipDirective } from '../../../../../shared/directives/tooltip/tooltip.directive';
import { LayersService } from '../../layers-page/data-access/services/layers.service';
import { AccordionModule } from 'primeng/accordion';
import { ListboxModule } from 'primeng/listbox';

@Component({
  selector: 'app-catalogue',
  standalone: true,
  imports: [
    KeyValuePipe,
    TooltipDirective,
    NgClass,
    AccordionModule,
    ListboxModule,
  ],
  templateUrl: './catalogue.component.html',
  styleUrl: './catalogue.component.css',
})
export class CatalogueComponent {
  readonly layerService: LayersService = inject(LayersService);

  addLayer(group: CategoryInfo, gI: number, layer: LayerInfo) {
    const lI = group.layers.indexOf(layer);
    this.layerService.addLayerToMap(group, gI, layer, lI);
  }

  removeLayer(group: CategoryInfo, gI: number, layer: LayerInfo) {
    const lI = group.layers.indexOf(layer);
    this.layerService.removeLayer(group, gI, layer, lI);
  }
}
