import { computed, Injectable, Signal, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RiskTableService {
  containerHeight = signal(350);
  scrollPosition = signal(0);
  selectedRow = signal(null);
  filterMetaData: any;
  sortMetaData: any;

  numberOfRows: Signal<number>;
  indexOfSelectedItem: Signal<number>;

  constructor() {
    this.numberOfRows = computed(() => Math.floor(this.containerHeight() / 23));
  }
}
