import { CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ModalService } from '../../../../shared/services/modal/modal.service';
import { MapService } from '../../data-access/services/map.service';
import { LayersService } from './data-access/services/layers.service';
import { ImageLayerComponent } from './ui/image-layer/image-layer.component';
import { LayerGroupComponent } from './ui/layer-group/layer-group/layer-group.component';
import { VectorLayerComponent } from './ui/vector-layer/vector-layer.component';
import { accordionAnimation2 } from '../../../../shared/animations/accordion.animation';
import { AccordionModule } from 'primeng/accordion';

@Component({
  selector: 'app-layers-page',
  templateUrl: './layers-page.component.html',
  styleUrls: ['./layers-page.component.css'],
  standalone: true,
  imports: [
    NgxPermissionsModule,
    CommonModule,
    LayerGroupComponent,
    VectorLayerComponent,
    ImageLayerComponent,
    CdkDropList,
    CdkDrag,
    AccordionModule,
  ],
  providers: [],
  animations: [accordionAnimation2],
})
export class LayersPageComponent {
  private readonly modalService: ModalService = inject(ModalService);
  public readonly mapService: MapService = inject(MapService);
  public readonly layersService: LayersService = inject(LayersService);

  constructor() {}

  drop(event: any) {
    const originalIndex =
      this.layersService.layerSignal.length - 1 - event.previousIndex;

    const reversedIndex =
      this.layersService.layerSignal.length - 1 - event.currentIndex;

    moveItemInArray(
      this.layersService.layerSignal,
      originalIndex,
      reversedIndex
    );

    moveItemInArray(
      this.mapService.map.getLayers().getArray(),
      originalIndex + 1,
      reversedIndex + 1
    );

    this.mapService.map.render();
  }
}
