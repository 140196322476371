<div class="title-bar">
    <div class="title">Password Reset</div>
    <div class="window-controls">
        <button (click)="cancel()"><span class="fa fa-times"></span></button>
    </div>
  </div>
  <div class="content">
    <form [formGroup]="form" class="grid grid-flow-row gap-2 py-4"
    (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input  autocomplete="password" formControlName="password" type="password" matInput>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>New Password</mat-label>
            <input  autocomplete="new-password" formControlName="newPassword" type="password" appPasswordPopover matInput>
          </mat-form-field>
    
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Confirm password</mat-label>
            <input autocomplete="new-password" formControlName="confirmPassword" type="password" matInput>
            @if (form.invalid) {
                      <mat-error>Your passwords don't match</mat-error>
            }
      
          </mat-form-field>
    </form>
  </div>
  <div class="footer">
    <button
    type="button"
    class="w-full flex justify-center px-4 py-2 disabled:bg-slate-400 bg-blue-600 text-white font-bold rounded-lg hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
  [disabled]="!form.valid"
    (click)="onSubmit()"
    type="submit"
  >
    Reset
  </button>
  </div>
  