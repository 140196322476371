import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, WritableSignal, effect, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';
import { Feature, Map, View } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { LineString, Point, Polygon } from 'ol/geom';
import { Circle, Fill, Icon, RegularShape, Stroke, Style } from 'ol/style';
import { single } from 'rxjs';
import { buffer } from 'ol/extent';
import { Type } from 'ol/geom/Geometry';


export type Kind = 'Polygon' | 'MultiPolygon' |  'Point' | 'LineString' | 'All';


@Component({
  selector: 'style-renderer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './style-renderer.component.html',
  styleUrl: './style-renderer.component.css'
})
export class StyleRendererComponent implements OnInit, AfterViewInit {

  kind = input<Kind>('All');

  private _style: WritableSignal<Style> = signal(null);

  @Input()
  set style(value: Style) {
    this._style.set(value)
  }

  get style(): Style {

    return this._style();
  }

  featureProperties = input({});

  styleEffect = effect(() => {
    if(this._style() == null)
    return;

    this.onStyleChange(this._style());
  } );





  strokeSize = signal(1);
  mapID:string = uuidv4();

  private vectorSource: VectorSource = new VectorSource();
  private layer =  new VectorLayer({source:this.vectorSource});

  olMap: Map;

  @ViewChild('map', {static:true}) mapContainerElement!: ElementRef;

  constructor() {
    effect(() => {
      this.vectorSource.clear();
      let geom = this.getExampleGeometry(this.kind());

      if(geom instanceof Array)
      {
        geom.forEach(geom => {
          let feature = new Feature({
            geometry: geom,
            Name: 'Example Geom',
          });
          this.vectorSource.addFeature(feature);
        })
      }
      else
      {
        let feature = new Feature({
          geometry: geom,
          Name: 'Example Geom',
        });

        feature.setProperties(this.featureProperties());


        this.vectorSource.addFeature(feature);

        this.olMap.getView().fit(feature.getGeometry().getExtent(), {padding:[5,5,5,5]})

      }
    })
  }

  ngOnInit(): void {

    this.olMap = new Map(
      {
        layers:[this.layer],
        controls:[],
        interactions:[],
        view: new View({
          projection: 'EPSG:4326'
        }),
      }
    )
  }

  onStyleChange(style)
  {

    if(typeof style === 'function')
    {
      this.layer.setStyle((feature, resolution) => {
        let originalStyle = style(feature, 100);
        if (originalStyle instanceof Style)
        {
          const originalImage = originalStyle.getImage();
          if (originalImage instanceof Icon) {
            originalImage.setScale(0.75);
          }
      }
      else if (Array.isArray(originalStyle)) {
          originalStyle.forEach(style => {
              const originalImage = style.getImage();
              if (originalImage instanceof Icon) {
                  originalImage.setScale(0.25);
              }
          });
      }

      return originalStyle;
      });
    }
    else{
      this.layer.setStyle(style)
    }


  }

  ngAfterViewInit(): void {
    this.olMap.setTarget(this.mapContainerElement.nativeElement);
    this.olMap.updateSize();




  }


  getExampleGeometry(kind: Kind)
  {
    switch (kind) {
      case 'Point':
        return new Point([7.10066, 50.735851]);
      case 'Polygon':
      case 'MultiPolygon':
        return new Polygon([[
          [7.1031761169433585, 50.734268655851345],
          [7.109270095825195, 50.734268655851345, ],
          [7.109270095825195, 50.73824770380063],
          [7.1031761169433585, 50.73824770380063],
          [7.1031761169433585, 50.734268655851345, ]
        ]]);
      case 'LineString':
        return new LineString([
          [7.062578201293945, 50.721786104206004],
          [7.077512741088867, 50.729610159968296],
          [7.082319259643555, 50.732435192351126],
          [7.097940444946289, 50.73748722929948],
          [7.106866836547852, 50.73775882875318],
          [7.117509841918945, 50.73889952925885],
          [7.129182815551758, 50.7504679214779]
        ]);
      case 'All':
        return [
          new Point([7.08542, 50.748851]),
          new Polygon([[
            [7.1031761169433585, 50.734268655851345],
            [7.109270095825195, 50.734268655851345, ],
            [7.109270095825195, 50.73122220380063],
            [7.1031761169433585, 50.73122220380063],
            [7.1031761169433585, 50.734268655851345, ]
          ]]),
          new LineString([
            [7.062578201293945, 50.721786104206004],
            [7.077512741088867, 50.729610159968296],
            [7.082319259643555, 50.732435192351126],
            [7.097940444946289, 50.73748722929948],
            [7.106866836547852, 50.73775882875318],
            [7.117509841918945, 50.73889952925885],
            [7.129182815551758, 50.7504679214779]
          ]),
        ];
      default:
        return new Point([7.10066, 50.735851]);
    }
  }






}
