import { Component, inject, input, viewChild } from '@angular/core';
import {
  LayersService,
  LayerState,
} from '../../data-access/services/layers.service';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { LayerOpacityDirective } from '../../utils/layer-opacity.directive';
import { LayerVisibilityDirective } from '../../utils/layer-visibility.directive';
import { layersControl } from '../../utils/layers.control';
import { accordionAnimation2 } from '../../../../../../shared/animations/accordion.animation';
import { AccordionModule, AccordionTab } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { CdkDragHandle } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-image-layer',
  templateUrl: './image-layer.component.html',
  styleUrls: ['./image-layer.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    LayerOpacityDirective,
    LayerVisibilityDirective,
    AccordionModule,
    InputSwitchModule,
    FormsModule,
    CdkDragHandle,
  ],
  animations: [accordionAnimation2],
})
export class ImageLayerComponent extends layersControl {
  private readonly http: HttpClient = inject(HttpClient);
  protected readonly layerService: LayersService = inject(LayersService);
  layer = input<LayerState>();
  layerGroup = input<LayerState>();
  accordion = viewChild(AccordionTab);

  legendObservable$ = toObservable(this.layer).pipe(
    filter(
      ({ sourceType, legendURL }) =>
        sourceType === 'ArcGISRest' && legendURL != null
    ),
    switchMap(({ legendURL, featureProperties }) =>
      this.http.get<any>(`${legendURL}`).pipe(
        catchError((error) => {
          console.error('Error fetching legend:', error);
          return of(JSON.stringify({ layers: [] }));
        }),
        map((response) => ({ response, featureProperties }))
      )
    ),
    map(({ response, featureProperties }) => {
      const layersToSelect = featureProperties['LAYERS'];
      const parsedResponse = response;
      const legend = [];
      const processLegendItems = (legendItems) => {
        legendItems.forEach(({ label, imageData }) => {
          legend.push({
            label,
            imageSrc: `data:image/png;base64,${imageData}`,
          });
        });
      };

      if (layersToSelect == null) {
        parsedResponse.layers.forEach(({ legend }) =>
          processLegendItems(legend)
        );
      } else {
        const layerId = parseInt(layersToSelect.split(':')[1]);
        const legendDefinition = parsedResponse.layers.find(
          ({ layerId: id }) => id === layerId
        );
        if (legendDefinition) {
          processLegendItems(legendDefinition.legend);
        }
      }

      return legend;
    })
  );

  legend = toSignal(this.legendObservable$);

  clickedOnEnableDisable(layer: any, accordion: AccordionTab) {
    /*if (!accordion.expanded) {
      accordion.toggle();
    }*/
  }

  removeLayer() {
    this.layerService.removeLayerFromMap(this.layerGroup(), this.layer());
  }
}
