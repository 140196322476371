import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appTooltip]',
  standalone: true
})
export class TooltipDirective implements OnDestroy {
  @Input('appTooltip') tooltipText!: string;
  @Input() tooltipPosition: 'left' | 'right' | 'top' | 'bottom' = 'right';

  private tooltipElement!: HTMLElement | null;

  constructor(private el: ElementRef, private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document) {}

    isTouchDevice(): boolean {
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    }

  @HostListener('mouseenter', ['$event']) onMouseEnter(event: MouseEvent): void {

    // if(this.isTouchDevice())return;

    event.stopPropagation();

    if (!this.tooltipElement) {
      this.showTooltip();
    }
  }

  @HostListener('mouseleave',['$event']) onMouseLeave(event: MouseEvent): void {

    // if(this.isTouchDevice())return;

    event.stopPropagation();

    if (this.tooltipElement) {
      this.hideTooltip();
    }
  }

  ngOnDestroy(): void {
    if (this.tooltipElement) {
      this.hideTooltip();
    }
  }

  private showTooltip(): void {
    this.tooltipElement = this.renderer.createElement('span');
    this.renderer.addClass(this.tooltipElement, 'esk-tooltip');
    this.renderer.addClass(this.tooltipElement, `esk-tooltip--${this.tooltipPosition}`);
    this.renderer.setProperty(
      this.tooltipElement,
      'textContent',
      this.tooltipText
    );


  this.renderer.appendChild(
    this.document.body,
    this.tooltipElement
  );


    const elRect = this.el.nativeElement.getBoundingClientRect();
  const tooltipRect = this.tooltipElement.getBoundingClientRect();

  let top, left;
  let padding = 8;
  switch (this.tooltipPosition) {
    case 'right':
      top = elRect.top + (elRect.height / 2) - (tooltipRect.height / 2) ;
      left = elRect.right + padding;
      break;
    case 'left':
      top = elRect.top + (elRect.height / 2) - (tooltipRect.height / 2);
      left = elRect.left - tooltipRect.width - padding;
      break;
    case 'top':
      top = elRect.top - tooltipRect.height - padding;
      left = elRect.left + (elRect.width / 2) - (tooltipRect.width / 2);
      break;
    case 'bottom':
      top = elRect.bottom + padding;
      left = elRect.left + (elRect.width / 2) - (tooltipRect.width / 2);
      break;
    default:
      top = elRect.top + (elRect.height / 2) - (tooltipRect.height / 2);
      left = elRect.right + padding;
  }

  this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
  this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);


  }

  private hideTooltip(): void {
    this.renderer.removeChild(
      this.document.body,
      this.tooltipElement
    );
    this.tooltipElement = null;
  }
}
