import { Injectable } from '@angular/core';
import { RiskWizard } from '../../risk-wizard-page/data-access/models/risk-wizard';
import { RiskLikelihoodState } from '../../risk-wizard-page/data-access/models/risk-likelihood-state.mode';
import {
  RatingColour,
  ValueColour,
} from '../../risk-wizard-page/data-access/models/hazard-types.model';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class RiskInfoService {
  _likelihood: string;
  likelihoodState: Map<string, Map<string, RiskLikelihoodState>>;
  _defaultColor = '#ffffff';

  set likelihood(likelihood: Observable<string>) {
    this.likelihoodState = new Map();
    likelihood.pipe(takeUntilDestroyed()).subscribe((next) => {
      this._likelihood = next;
      this.updateLikelihood();
    });
  }

  get likelihood(): string {
    return this._likelihood;
  }

  getCtrlEffectivenessColour(ctrlStr: string, ctrlExp: string): string {
    if (ctrlStr === '' || ctrlExp === '') {
      return this._defaultColor;
    }
    return RiskWizard.CTRL_EFFECTIVE_MATRIX[ctrlStr][ctrlExp.toUpperCase()]
      .colour;
  }

  getLikelihoodColour(likelihood: string, ctrlEffectiveness: string): string {
    if (likelihood === '' || ctrlEffectiveness === '') {
      return this._defaultColor;
    }
    return RiskWizard.LIKELIHOOD_AFTER_CTRL_MATRIX[likelihood][
      ctrlEffectiveness.toUpperCase()
    ].colour;
  }

  getRiskLevelColour(likelihoodAfterCtrl: string, consequence: string): string {
    if (likelihoodAfterCtrl === '' || consequence === '') {
      return this._defaultColor;
    }
    return RiskWizard.RISK_MATRIX[likelihoodAfterCtrl][
      consequence.toUpperCase()
    ].colour;
  }

  getPriorityLevelColour(
    confLvl: string,
    consequence: string,
    likelihoodAfterCtrl: string
  ): string {
    if (confLvl === '' || consequence === '' || likelihoodAfterCtrl === '') {
      return this._defaultColor;
    }
    return RiskWizard.PRIORITY_LEVELS[confLvl][consequence][likelihoodAfterCtrl]
      .colour;
  }

  updateLikelihood() {
    this.likelihoodState.forEach((value) => {
      value.forEach((value1, key1) => {
        value1.likelihood$.next(this.likelihood);
        this.likelihoodState.set(key1, value);
      });
    });
  }

  getCtrlEffectiveness(infrastructure: string, group: any): ValueColour {
    return this.likelihoodState.get(infrastructure).get(this.getGroupKey(group))
      .ctrlEffectiveness$.value;
  }

  getLikelihoodAfterCtrl(infrastructure: string, group: any): ValueColour {
    return this.likelihoodState.get(infrastructure).get(this.getGroupKey(group))
      .likelihoodAfterCtrl$.value;
  }

  getRiskLevel(infrastructure: string, group: any): ValueColour {
    return this.likelihoodState.get(infrastructure).get(this.getGroupKey(group))
      .riskLevel$.value;
  }

  getPriorityLevel(infrastructure: string, group: any): RatingColour {
    return this.likelihoodState.get(infrastructure).get(this.getGroupKey(group))
      .priorityLevel$.value;
  }

  getFormGroup(group: any): FormGroup {
    return group as FormGroup;
  }

  getGroupKey(group: any) {
    return Object.keys(group.controls)[0];
  }

  getMaxConsequence(multiImpArea: any[]) {
    if (multiImpArea && multiImpArea.length > 0) {
      const max = multiImpArea.reduce((max, current) => {
        let currentKey: string = Object.keys(current)[0];
        let maxKey: string = Object.keys(max)[0];

        let curr = current[currentKey]['consequence'];
        let maxV = max[maxKey]['consequence'];

        return curr > maxV ? current : max;
      });
      return max[Object.keys(max)[0]]['consequence'];
    }
  }

  setFormGroupControlValueChanges(
    infrastructure: string,
    formBuilder: FormBuilder,
    key: string,
    disabled: boolean = false
  ) {
    const formCtrlDefault = { value: '', disabled: disabled };
    const group = formBuilder.group({
      [key]: formBuilder.group({
        consequence: [formCtrlDefault, [Validators.required]],
        structures: [{ value: [], disabled: disabled }, [Validators.required]],
        ctrlStrength: [formCtrlDefault, [Validators.required]],
        ctrlExpediency: [formCtrlDefault, [Validators.required]],
        confidenceLvl: [formCtrlDefault, [Validators.required]],
        impactNature: [formCtrlDefault, [Validators.required]],
        existingControls: [formCtrlDefault, [Validators.required]],
      }),
    });

    let likelihoodState: RiskLikelihoodState = new RiskLikelihoodState(
      group.get(`${key}.consequence`).valueChanges,
      group.get(`${key}.ctrlStrength`).valueChanges,
      group.get(`${key}.ctrlExpediency`).valueChanges,
      group.get(`${key}.confidenceLvl`).valueChanges
    );
    if (this.likelihoodState.has(infrastructure)) {
      this.likelihoodState.get(infrastructure).set(key, likelihoodState);
    } else {
      this.likelihoodState.set(
        infrastructure,
        new Map<string, RiskLikelihoodState>().set(key, likelihoodState)
      );
    }
    this.updateLikelihood();

    return group;
  }

  removeImpactedArea(
    infrastructure: string,
    key: string,
    impactedAreas: FormArray
  ) {
    const index: number = impactedAreas.controls.findIndex(
      (v: FormGroup) => key in v.controls
    );
    if (index !== -1) {
      impactedAreas.removeAt(index);
      this.likelihoodState.get(infrastructure).delete(key);
      if (this.likelihoodState.get(infrastructure).size === 0) {
        this.likelihoodState.delete(infrastructure);
      }
    }
  }

  getConsequenceValue(name: string): number {
    return RiskWizard.CONSEQUENCE.find((c) => c.name === name).value;
  }

  getConsequenceName(value: number): string {
    return RiskWizard.CONSEQUENCE.find((c) => c.value === value).name;
  }
}
