<p-accordion>
  <div cdkDropList (cdkDropListDropped)="drop($event)" class="layers">
    @for (layerGroup of layersService.layerSignal.slice().reverse(); track layerGroup().title) {
      @switch (layerGroup().type) {
        @case ('group') {
          <app-layer-group cdkDrag [layerGroup]="layerGroup()" [map]="mapService.getMap()"></app-layer-group>
        }
        @case ('vector') {
          <app-vector-layer cdkDrag [layer]="layerGroup()"></app-vector-layer>
        }
        @case ('image') {
          <app-image-layer cdkDrag [layer]="layerGroup()"></app-image-layer>
        }
        @default {}
      }
    }
  </div>
</p-accordion>
