import BaseLayer from 'ol/layer/Base';

export class layersControl {
  changeVisibility(
    baseLayer: BaseLayer,
    currentVisibleState: 'visible' | 'hidden' | 'partial'
  ) {
    if (currentVisibleState == 'visible' || currentVisibleState == 'partial') {
      baseLayer.setVisible(false);
    } else {
      baseLayer.setVisible(true);
    }
  }
}
