import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  effect,
  input,
  inject,
} from '@angular/core';
import { VectorLayerComponent } from '../../vector-layer/vector-layer.component';
import BaseLayer from 'ol/layer/Base';
import { CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import LayerGroup from 'ol/layer/Group';
import { Map } from 'ol';
import {
  LayersService,
  LayerState,
} from '../../../data-access/services/layers.service';
import { ImageLayerComponent } from '../../image-layer/image-layer.component';
import { accordionAnimation2 } from '../../../../../../../shared/animations/accordion.animation';
import { layersControl } from '../../../utils/layers.control';
import { Accordion, AccordionModule } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from 'primeng/dragdrop';
import { LayerVisibilityDirective } from '../../../utils/layer-visibility.directive';

@Component({
  selector: 'app-layer-group',
  templateUrl: './layer-group.component.html',
  styleUrls: ['./layer-group.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    VectorLayerComponent,
    CdkDropList,
    CdkDrag,
    ImageLayerComponent,
    AccordionModule,
    InputSwitchModule,
    FormsModule,
    DragDropModule,
    LayerVisibilityDirective,
  ],
  animations: [accordionAnimation2],
})
export class LayerGroupComponent extends layersControl implements OnInit {
  @ViewChild(Accordion) accordion: Accordion;
  layerGroup = input<LayerState>();
  map = input.required<Map>();
  hasParent = input<boolean>(false);
  parentAccordion = input<Accordion>();
  parentExpanded = input<boolean>();
  protected readonly layerService: LayersService = inject(LayersService);

  private parentStateChanged = effect(() => {
    if (this.parentExpanded()) {
      return;
    }

    if (this.parentAccordion() == null) {
      return;
    }

    //TODO this.parentAccordion().closeAll();
  });

  ngOnInit() {}

  drop(event: any) {
    var layer = event.item.data as BaseLayer;
    var layerGroup = event.container.data as LayerGroup;

    const originalIndex =
      layerGroup.getLayers().getArray().length - 1 - event.previousIndex;

    const reversedIndex =
      layerGroup.getLayers().getArray().length - 1 - event.currentIndex;

    moveItemInArray(this.layerGroup().children, originalIndex, reversedIndex);

    moveItemInArray(
      layerGroup.getLayers().getArray(),
      originalIndex,
      reversedIndex
    );

    this.map().render();
  }
}
