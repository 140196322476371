<p-card id="banner" header="Risk Information Portal Demonstrator">
</p-card>
<div id="zoomLevel">
  Zoom Level: {{mapService.currentZoomLevel | number: '1.0-2'}}
</div>
<sidebar [routeConfig]="routeConfig" routePrefix="/map">
  <div #map class="map"></div>
  @if (mapService.retrievingDataFromService) {
    <div class="loading-msg">
      <mat-spinner [diameter]="50"></mat-spinner>
      <div>Retrieving boundary from the Web Service ...</div>
    </div>
  }
  <app-layer-info-dialog #overlay></app-layer-info-dialog>
  <app-bottom-drawer></app-bottom-drawer>
</sidebar>
