import {
  AfterViewInit,
  Component,
  effect,
  ElementRef,
  inject,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DialogModule } from '@angular/cdk/dialog';
import { KeyValuePipe } from '@angular/common';
import { CdkAccordion, CdkAccordionItem } from '@angular/cdk/accordion';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { SidebarService } from '../../ui/sidebar/data-access/sidebar.service';
import { MapService } from '../../data-access/services/map.service';
import { AttrDictionary } from '../../data-access/models/attribute-key-dictionary';

@Component({
  selector: 'app-layer-info-dialog',
  standalone: true,
  imports: [
    DialogModule,
    KeyValuePipe,
    CdkAccordionItem,
    CdkAccordion,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './layer-info-dialog.component.html',
  styleUrl: './layer-info-dialog.component.css',
})
export class LayerInfoDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('popupContainer', { static: true }) popupContainer: ElementRef;
  layerState: Map<string, object> = new Map();
  loadingLayers: string[] = [];

  public readonly mapService: MapService = inject(MapService);
  readonly sidebarService: SidebarService = inject(SidebarService);
  private readonly renderer: Renderer2 = inject(Renderer2);

  constructor() {
    effect(() => {
      this.layerState = new Map();
      this.loadingLayers = [];
      this.mapService.layerInfoState().forEach((value, key) => {
        if (value().state === 'loaded') {
          this.layerState.set(key, value().data);
        } else if (value().state === 'loading') {
          this.loadingLayers.push(key);
        }
      });
    });

    effect(() => {
      if (this.sidebarService.drawerState()) {
        this.translateDraggable();
      }
    });
  }

  ngAfterViewInit() {
    new ResizeObserver(() => this.translateDraggable()).observe(
      this.popupContainer!.nativeElement
    );
  }

  ngOnDestroy() {
    this.sidebarService.drawer.openedChange.unsubscribe();
  }

  cancel() {
    this.resetDraggablePosition();
    this.mapService.overlay.setPosition(undefined);
  }

  isSingleObject(data: any): boolean {
    return Object.keys(data).length == 1;
  }

  objectValue(value: any): string {
    return value[Object.keys(value)[0]].toString();
  }

  isPDFFile(key: string, value: any): boolean {
    return (
      key.toLowerCase().startsWith('filename') &&
      value.toString().toLowerCase().endsWith('.pdf')
    );
  }

  getUrl(layer: string, value: string): string {
    layer = layer === 'Human Settlement Areas' ? 'HSA' : 'LGA';
    return `https://dev.riskinformation.eskspatial.com.au/api/v1/assets/Reports/${
      layer.split(' ')[0]
    }/${value}`;
  }

  getValueFromDictionary(key: string): string {
    return AttrDictionary.ATTR_DICT[key.toLowerCase()]?.displayName;
  }

  resetDraggablePosition() {
    console.log('reset called');
    this.renderer.setStyle(
      this.popupContainer.nativeElement,
      'transform',
      'translate3d(0, 0, 0)'
    );
  }

  translateDraggable() {
    const popUpRect = this.popupContainer.nativeElement.getBoundingClientRect();
    const sideNavRect = (
      this.sidebarService.drawer as any
    )._elementRef.nativeElement.getBoundingClientRect();

    console.log('translate draggable');
    if (sideNavRect.width !== 0 && popUpRect.right > sideNavRect.left) {
      const translateX: number = sideNavRect.left - popUpRect.right;
      this.renderer.setStyle(
        this.popupContainer.nativeElement,
        'transform',
        `translateX(${translateX}px)`
      );
    }
  }

  getFileName(key: string) {
    return key.replace('filename_', '');
  }
}
