import { Component, inject } from '@angular/core';
import { TooltipDirective } from '../../../../shared/directives/tooltip/tooltip.directive';
import { RiskTableComponent } from './ui/risk-table/risk-table.component';
import {
  MatBottomSheet,
  MatBottomSheetModule,
} from '@angular/material/bottom-sheet';
import { SidebarService } from '../../ui/sidebar/data-access/sidebar.service';

@Component({
  selector: 'app-bottom-drawer',
  standalone: true,
  imports: [TooltipDirective, MatBottomSheetModule],
  templateUrl: './bottom-drawer.component.html',
})
export class BottomDrawerComponent {
  sidebarService: SidebarService = inject(SidebarService);

  constructor(private bottomSheet: MatBottomSheet) {}

  openInfoTable() {
    this.sidebarService.close();
    this.bottomSheet.open(RiskTableComponent, {
      hasBackdrop: false,
      panelClass: 'table-height',
      data: { service: this.sidebarService },
    });
  }
}
