import { BehaviorSubject, Observable } from 'rxjs';
import { Consequence, RatingColour, ValueColour } from './hazard-types.model';
import { RiskWizard } from './risk-wizard';
import { convertToTitleCase } from '../../../../../../shared/utils/utils';

export class RiskLikelihoodState {
  ctrlEffectiveness$: BehaviorSubject<ValueColour>;
  likelihoodAfterCtrl$: BehaviorSubject<ValueColour>;
  riskLevel$: BehaviorSubject<ValueColour>;
  priorityLevel$: BehaviorSubject<RatingColour>;
  likelihood$: BehaviorSubject<string>;

  _cons: Consequence;
  _ctrlStr: string;
  _ctrlExp: string;
  _confLvl: string;

  constructor(
    cons: Observable<any>,
    ctrlStr: Observable<string>,
    ctrlExp: Observable<string>,
    confLvl: Observable<string>
  ) {
    this.ctrlEffectiveness$ = new BehaviorSubject<ValueColour>(
      this._baseValueColour
    );
    this.likelihoodAfterCtrl$ = new BehaviorSubject<ValueColour>(
      this._baseValueColour
    );
    this.riskLevel$ = new BehaviorSubject<ValueColour>(this._baseValueColour);
    this.priorityLevel$ = new BehaviorSubject<RatingColour>({} as RatingColour);
    this.likelihood$ = new BehaviorSubject<string>('');

    this.likelihood$.subscribe(() => {
      if (
        this.ctrlEffectiveness$.value &&
        this.ctrlEffectiveness$.value.value !== ''
      ) {
        this.updateLikelihoodAfterCtrl();
        this.updateRiskLevel();
        this.updatePriorityLevel();
      }
    });

    this.likelihoodAfterCtrl$.subscribe(() => {
      this.updateRiskLevel();
      this.updatePriorityLevel();
    });

    cons.subscribe((next: any) => {
      this._cons = RiskWizard.CONSEQUENCE.find((s) => s.value === next);
      this.updateRiskLevel();
      this.updatePriorityLevel();
    });

    ctrlStr.subscribe((next) => {
      this._ctrlStr = next;
      if (this._ctrlExp && this._ctrlExp !== '') {
        this.updateCtrlEffectivenessMatrix(next, this._ctrlExp.toUpperCase());
        this.updateLikelihoodAfterCtrl();
      }
    });

    ctrlExp.subscribe((next) => {
      this._ctrlExp = next;
      if (this._ctrlStr && this._ctrlStr !== '') {
        this.updateCtrlEffectivenessMatrix(this._ctrlStr, next.toUpperCase());
        this.updateLikelihoodAfterCtrl();
      }
    });

    confLvl.subscribe((next) => {
      this._confLvl = next;
      this.updatePriorityLevel();
    });
  }

  get _baseValueColour(): ValueColour {
    return {
      value: '',
      colour: '#ffffff',
    };
  }

  updateCtrlEffectivenessMatrix(ctrlStr: string, ctrlExp: string) {
    this.ctrlEffectiveness$.next(
      RiskWizard.CTRL_EFFECTIVE_MATRIX[ctrlStr][ctrlExp]
    );
  }

  updateLikelihoodAfterCtrl() {
    this.likelihoodAfterCtrl$.next(
      RiskWizard.LIKELIHOOD_AFTER_CTRL_MATRIX[this.likelihood$.value][
        this.ctrlEffectiveness$.value.value
      ]
    );
  }

  updateRiskLevel() {
    if (
      this.likelihoodAfterCtrl$.value &&
      this.likelihoodAfterCtrl$.value.value !== '' &&
      this._cons &&
      this._cons.name !== ''
    )
      this.riskLevel$.next(
        RiskWizard.RISK_MATRIX[
          convertToTitleCase(this.likelihoodAfterCtrl$.value.value)
        ][this._cons.name.toUpperCase()]
      );
  }

  updatePriorityLevel() {
    if (
      this._cons &&
      this._confLvl &&
      this._confLvl !== '' &&
      this.likelihoodAfterCtrl$.value &&
      this.likelihoodAfterCtrl$.value.value !== ''
    ) {
      this.priorityLevel$.next(
        RiskWizard.PRIORITY_LEVELS[this._confLvl][this._cons.name][
          convertToTitleCase(this.likelihoodAfterCtrl$.value.value)
        ]
      );
    }
  }
}
