<div cdkDrag #popupContainer class="ol-popup">
  <div class="title-bar">
    <div cdkDragHandle>
      <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
        <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
        <path d="M0 0h24v24H0z" fill="none"></path>
      </svg>
    </div>
    <div class="title">Layer(s) Info</div>
    <div class="window-controls">
      <button (click)="cancel()"><span class="fa fa-times"></span></button>
    </div>
  </div>
  <div class="content">
    @for (layer of layerState | keyvalue; track layer; let i = $index) {
      @if (isSingleObject(layer.value)) {
        <p>{{layer.key}}: {{objectValue(layer.value)}}</p>
      } @else {
        <cdk-accordion class="info-accordion">
          <cdk-accordion-item
            #accordionItem="cdkAccordionItem"
            class=""
            role="button"
            tabindex="0"
            [attr.id]="'accordion-header-' + 1"
            [attr.aria-expanded]="accordionItem.expanded"
            [attr.aria-controls]="'accordion-body-' + 1">
            <div class="flex justify-between" (click)="accordionItem.toggle()">
              {{layer.key}}
              <span>
                @if (accordionItem.expanded) {
                  <i class="fa-solid fa-chevron-up"></i>
                } @else {
                  <i class="fa-solid fa-chevron-down"></i>
                }
              </span>
            </div>
            <div
              class="info-accordion-item-body"
              role="region"
              [style.display]="accordionItem.expanded ? '' : 'none'"
              [attr.id]="'accordion-body-' + i"
              [attr.aria-labelledby]="'accordion-header-' + i">
              <table class="table-auto">
                <thead>
                <tr>
                  <th>Attribute</th>
                  <th>Value</th>
                </tr>
                </thead>
                <tbody>
                  @for (prop of layer.value | keyvalue; track prop) {
                    <tr class="even:bg-gray-50 odd:bg-white">
                      @if (isPDFFile(prop.key, prop.value)) {
                        <td colspan="2">
                          <a [href]="getUrl(layer.key, prop.value)" target="_blank" class="flex justify-center items-center">
                            <i class="fa-solid fa-file-pdf" style="color: #841906;"></i> {{getFileName(prop.key)}} Report</a>
                        </td>
                      }
                      @else {
                        <td>{{getValueFromDictionary(prop.key)}}</td>
                        <td>{{prop.value}}</td>
                      }
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </cdk-accordion-item>
        </cdk-accordion>
      }
    }
    @if (loadingLayers.length >= 1) {
      <p>Loading Layers...</p>
    }
  </div>
</div>
