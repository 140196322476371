import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { RiskWizard } from '../../risk-wizard-page/data-access/models/risk-wizard';
import { RiskDetail } from '../../risk-wizard-page/data-access/models/risk-detail.model';

@Injectable({
  providedIn: 'root',
})
export class CsvExporterService {
  populateCSV(riskInfo: RiskDetail): string[] {
    const {
      riskRef,
      hazardType,
      analysisZone,
      locations,
      infrastructures,
      hazardStatement,
      consequenceStatement,
      event,
      likelihood,
    } = riskInfo;

    const infs = Object.keys(infrastructures);
    const firstInf = `${riskRef},${hazardType},${analysisZone},${locations},${infs
      .join('; ')
      .replaceAll(',', ';')},${this._replaceNewLine(
      hazardStatement
    )},${this._replaceNewLine(
      consequenceStatement.replaceAll(',', ';')
    )},${event},${likelihood}`;
    const secondInf = ',,,,,,,,';
    const content: string[] = [];

    infs.forEach((key, i) => {
      const { impactArea, feature, impactedAreas, maxConsequence } =
        infrastructures[key];
      const maxConsNam = maxConsequence
        ? RiskWizard.CONSEQUENCE.find(
            (c) => c.value === parseInt(maxConsequence)
          ).name
        : '';
      const impArea = impactArea.length === 0 ? '' : impactArea.join('; ');
      const infDetails = `${key.replaceAll(
        ',',
        ';'
      )},${feature},${impArea},${maxConsNam}`;
      const altDetails = `,,,`;

      let row: string =
        i === 0 ? `${firstInf},${infDetails}` : `${secondInf},${infDetails}`;

      if (impactedAreas.length === 0) {
        content.push(`${row},,,,,,,,,,,,`);
      } else {
        impactedAreas.forEach((ia, index) => {
          const {
            consequence,
            structures,
            controlStrength,
            controlExpediency,
            confidenceLevel,
            natureOfImpactTo,
            existingControls,
            controlEffectiveness,
            likelihoodAfterControl,
            risk,
            priority,
          } = ia;

          const structDName = structures.map((a) => a.displayName).join('; ');
          const impArea: string = `${
            impactArea[index]
          },${consequence},${structDName},${this._replaceNewLine(
            existingControls
          )},${controlStrength},${controlExpediency},${confidenceLevel},${this._replaceNewLine(
            natureOfImpactTo
          )},${controlEffectiveness},${likelihoodAfterControl},${risk},${priority}`;

          if (index === 0) {
            content.push(`${row},${impArea}`);
          } else {
            content.push(`${secondInf},${altDetails},${impArea}`);
          }
        });
      }
    });

    return content;
  }

  exportRiskAsCSV(data: string[], riskRef: string) {
    const blob = new Blob([data.join('\n')], {
      type: 'text/csv;charset=utf-8;',
    });

    saveAs(blob, `${riskRef}_${new Date().toDateString()}.csv`);
  }

  _replaceNewLine(str: string): string {
    return str.replaceAll('\n', ' ').replaceAll(',', ':');
  }
}
