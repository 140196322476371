import { inject, Injectable } from '@angular/core';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Draw } from 'ol/interaction';
import { Feature } from 'ol';
import { LineString, MultiPolygon, Point } from 'ol/geom';
import { RasterInfo } from '../../../../data-access/models/raster-info.model';
import { BehaviorSubject, tap } from 'rxjs';
import { Risk } from '../models/risk-detail.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RiskWizardService {
  readonly RISK_URL: string = `${environment.apiUrl}risk`;
  private readonly httpClient: HttpClient = inject(HttpClient);
  allRisks$: BehaviorSubject<Risk[]> = new BehaviorSubject([]);
  hazardSource: VectorSource;
  hazardLayer: VectorLayer<any>;
  hazardBoundary: Feature<MultiPolygon> | Feature<Point> | Feature<LineString>;
  coordinates = [];
  interaction: Draw;
  interactionType: 'Info' | 'Draw' | 'Select' = 'Info';
  completePolygon: boolean = false;
  rasterInfo: RasterInfo[];
  bridgeName: string;

  clearExistingFeatureInfo() {
    this.bridgeName = '';
    this.rasterInfo = [];
  }

  loadRisks() {
    this.httpClient.get<Risk[]>(`${this.RISK_URL}/all`).subscribe((next) => {
      this.allRisks$.next(next);
    });
  }

  persistData(data: string) {
    this.httpClient
      .post(`${this.RISK_URL}/create`, { data: data })
      .pipe(tap(() => this.loadRisks()))
      .subscribe();
  }

  updateData(id: number, data: string) {
    this.httpClient
      .post(`${this.RISK_URL}/${id}/update`, { data: data })
      .pipe(tap(() => this.loadRisks()))
      .subscribe();
  }
}
