<p-accordionTab class="w-full">
  <ng-template pTemplate="header">
    <div cdkDragHandle class="w-full">
      <div class="layer">
        <div class="flex items-center gap-5">
          <p-inputSwitch [ngModel]="layerService.isLayerVisible(layer())" LayerVisibilityControl [layer]="layer().layer"
                         [currentVisibleState]="layer().visible" [disabled]="!layer().canActivate"
                         (onChange)="clickedOnEnableDisable(layer().layer, accordion())" class="small-input-switch"/>
          <span class="layer-title">{{ layer().title }}</span>
        </div>
        <div class="flex items-center gap-5">
          <input autocomplete="off" data-lpignore="true" #opacityInput type="range" min="0" max="1" step="0.01"
                 layerOpacityControl [layer]="layer().layer" [value]="layer().opacity" class="styled-range"/>
          <button (click)="removeLayer()">
            <span><i class="fa fa-trash fa"></i></span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <div role="region">
    <div class="flex flex-col justify-center align-start gap--xs">
      @if (layer().legendURL && layer().sourceType != 'ArcGISRest') {
        <img [src]="layer().legendURL" alt="" srcset="" class="img-fit"/>
      }
      @else if(layer().legendURL && layer().sourceType == 'ArcGISRest') {
        @for (legendItem of legend(); track $index) {
          <span class="flex align-center gap--md">
            <img [src]="legendItem.imageSrc" alt="" />{{ legendItem.label }}
          </span>
        }
      }
      @if (layer().minZoom && layer().minZoom > layerService.mapService.currentZoomLevel) {
        <span class="min-zoom-msg">To view this layer the zoom level needs to be {{layer().minZoom}} or greater</span>
      }
    </div>
  </div>
</p-accordionTab>
